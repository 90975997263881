import { lazy } from 'react';
import UserControl from '../components/Dashboard/UserControl';
/* import User from "../components/User/User";
import Roles from "../components/Roles/Roles";
import Permission from "../components/Permissions/Permission"; */
import RolesAddPage from '../components/Roles/RolesPage/RolesAddPage';
import RolesEditPage from '../components/Roles/RolesPage/RolesEditPage';
import UserRolesList from '../components/User/UserRoles/UserRolesList';
import DocumentManagement from '../components/DocumentManagement/DocumentManagement';
import PermissionPage from '../components/Permissions/PermissionPage';
import UserRolesAdd from '../components/User/UserRoles/UserRolesAdd';
import UserAddNewPage from '../components/User/UserCRUD/UserAddNewPage';
import UserEditNewPage from '../components/User/UserCRUD/UserEditNewPage';
import PermissionAddPage from '../components/Permissions/PermissionsCRUD/PermissionAddPage';
import Products from '../components/Products/Products';
import ProductsDraftList from '../components/Products/ProductsDraftList';
import ProductsApprovalList from '../components/Products/ProductsApprovalList';

import AddProducts from '../components/Products/AddProducts';
import DraftProducts from '../components/Products/DraftProducts';
import UpdateProducts from '../components/Products/UpdateProducts';
import ViewProduct from '../components/Products/ViewProduct';
import ProvideEmail from '../components/ForgotPassword/ProvideEmail';
import ChangePassword from '../components/ForgotPassword/ChangePassword';

import UserChangePassword from '../components/User/UserChangePassword';

import DocumentPreviewMain from '../components/DocumentManagement/DocumentPreviewMain';
import InventoryReport from '../components/Inventory/InventoryReport';
import InventoryReportView from '../components/Inventory/InventoryReportView';
import InventoryReportWarehouse from '../components/Inventory/InventoryReportWarehouse';
import InventoryReportMovementResult from '../components/Inventory/InventoryReportMovementResult';

import Customers from '../components/Customer/List/Customers';
import ViewCustomer from '../components/Customer/List/views/ViewCustomer';

// testing only
import SalesInvoiceTemplateSetup from '../components/Customer/List/views/CustomerSalesInvoiceTemplateSetup';

// pages
import LoginPage from '../pages/Login';
import CrossLoginPage from '../pages/Login/CrossLogin';
import Approver from '../pages/TreasuryTrade/Approver';
import RevisionHistory from '../pages/RevisionHistory';

//import Dashboard from "../components/Dashboard/Dashboard";

const Dashboard = lazy(() => import('../components/Dashboard/Dashboard'));

/* list of all routes for the website */
const oldRoute = {
  public: [
    { name: 'login', path: '/', component: LoginPage },
    {
      name: 'cross-login',
      path: '/cross-login/:euid/:token',
      component: CrossLoginPage,
    },
    { name: 'provide_email', path: '/provide-email', component: ProvideEmail },
    {
      name: 'change_password',
      path: '/change-password',
      component: ChangePassword,
    },
  ],
  // maybe we can breakdown this into admin and user ?
  auth: [
    {
      name: 'dashboard',
      path: '/dashboard',
      component: Dashboard,
    },
    {
      name: 'user_control',
      path: '/user-control',
      component: UserControl,
    },
    {
      name: 'user_add',
      path: '/add/user',
      component: UserAddNewPage,
    },
    {
      name: 'user_edit',
      path: '/edit/user/:id',
      component: UserEditNewPage,
    },
    {
      name: 'user_change_password',
      path: '/user/:id/change-password',
      component: UserChangePassword,
    },
    {
      name: 'roles_add',
      path: '/add/roles',
      component: RolesAddPage,
    },
    {
      name: 'roles_edit',
      path: '/edit/roles/:id',
      component: RolesEditPage,
    },
    {
      name: 'role_list',
      path: '/user/:id/role',
      component: UserRolesList,
    },
    {
      name: 'role_user_add',
      path: '/user/:id/add/role',
      component: UserRolesAdd,
    },
    {
      name: 'history',
      path: '/history',
      component: RevisionHistory,
    },
    {
      name: 'document_management',
      path: '/docmanagement',
      component: DocumentManagement,
    },
    {
      name: 'document_preview',
      path: '/preview/:id',
      component: DocumentPreviewMain,
    },
    {
      name: 'products',
      path: '/products',
      component: Products,
    },
    {
      name: 'products_draft',
      path: '/draft/list/products',
      component: ProductsDraftList,
    },
    {
      name: 'products_draft_view',
      path: '/draft/products/:id',
      component: DraftProducts,
    },
    {
      name: 'products_approval',
      path: '/approval/list/products',
      component: ProductsApprovalList,
    },
    {
      name: 'products_add',
      path: '/add/products',
      component: AddProducts,
    },
    {
      name: 'products_update',
      path: '/update/products/:product_code',
      component: UpdateProducts,
    },
    {
      name: 'products_view',
      path: '/view/products/list/:product_code',
      component: ViewProduct,
    },
    {
      name: 'permission_create',
      path: '/permissions/fields/create',
      component: PermissionAddPage,
    },
    {
      name: 'permission',
      path: '/user-control#tab-top/:id',
      component: PermissionPage,
    },
    {
      name: 'customers',
      path: '/customers',
      component: Customers,
    },
    {
      name: 'customer_view_per_id',
      path: '/view/customer/:customer_code',
      component: ViewCustomer,
    },
    {
      name: 'customer_sales_invoice_template_setup',
      path: '/view/customer/salesInvoice/templateSetup',
      component: SalesInvoiceTemplateSetup,
    },
    {
      name: 'inventoryreport',
      path: '/inventoryreport',
      component: InventoryReport,
    },
    // {
    //   name: "inventoryreport_movement",
    //   path: "/inventory/report/movement",
    //   component: InventoryReportMovement,
    // },
    {
      name: 'inventoryreport_summary',
      path: '/view/summary/inventoryreport',
      component: InventoryReportView,
    },
    {
      name: 'inventoryreport_warehouse',
      path: '/view/summary_warehouse/inventoryreport',
      component: InventoryReportWarehouse,
    },
    {
      name: 'inventoryreport_movement_result',
      path: '/view/summary_warehouse/inventoryreport/movement',
      component: InventoryReportMovementResult,
    },
    {
      name: 'list_of_approvals',
      path: '/treasury/list-of-approvals',
      component: Approver,
    },
  ],
};

export default oldRoute;
