import React, { useState, useEffect, Fragment } from 'react';
import useForm from '../../useForm';
import axiosInstance from '../axiosInstance';
import { useHistory } from 'react-router';
import Select from 'react-select';
import DefaultLayout from '../Layouts/DefaultLayout';
import PrimaryLoader from '../PrimaryLoader';
import { Form, Tabs, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useNavActive from '../Navbar/useNavActive';
import { AlertDefaultError, PermissionsGate } from '../PermissionsGate';

// const columns = [
//   { field: "prod_code", headerName: "Prod Code", width: 150 },
//   { field: "prod_name", headerName: "Prod Name", width: 150 },
//   { field: "uom", headerName: "UOM", width: 150 },
//   { field: "qty", headerName: "Qty", width: 150 },
//   { field: "unit_price", headerName: "Unit Price", width: 150 },
//   { field: "total", headerName: "Total", width: 150 },
//   { field: "in_stock", headerName: "In Stock", width: 150 },
//   { field: "request", headerName: "Request", width: 150 },
//   { field: "batch", headerName: "Batch", width: 150 },
//   { field: "for_inv", headerName: "For Inv", width: 150 },
//   { field: "for_rete", headerName: "For Rete", width: 150 },
//   { field: "location", headerName: "Location", width: 150 },
//   { field: "remarks", headerName: "Remarks", width: 150 },
// ];

const InventoryReport = () => {
  useNavActive(['inventory', 'inventory_report']);

  const [prodNameHolder, setProdNameHolder] = useState([]);
  const [warehouseHolder, setWarehouseHolder] = useState([]);
  const [groupHolder, setGroupHolder] = useState([]);
  const [segmentHolder, setSegmentHolder] = useState([]);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [visiblediv, setVisibleDiv] = useState(false);
  const [visiblebutton, setVisibleButton] = useState(false);

  const [loader, setLoader] = useState(false);

  const [tableType, setTableType] = useState('');

  const [productname_options, setproductname_options] = useState([]);
  const [productgroup_options, setproductgroup_options] = useState([]);
  const [productsegment_options, setproductsegment_options] = useState([]);
  const [warehouse_options, setwarehouse_options] = useState([]);

  // const [values, handleChange] = useForm({
  //   report_date_to: "",
  //   report_date_from: "",
  // });

  const [formFilter, setFormFilter] = useState({
    product_name: [],
    product_group: [],
    product_segment: [],
    warehouse: [],
    table_col: '',
    report_date_from: '',
    report_date_to: '',
  });

  // $request->warehouse
  // $request->product_name
  // $request->product_segment
  // $request->report_date_from
  // $request->report_date_to
  // $request->product_group

  useEffect(() => {}, []);

  useEffect(() => {
    listProducts();
    listGroup();
    listSegment();
    listWarehouse();
  }, []);

  const listProducts = () => {
    axiosInstance.get(`/inventoryreport/dropdown/product`).then((response) => {
      let result = response.data.response.result;
      setproductname_options(
        result.map((row) => ({
          label: row.product_name,
          value: row.product_code,
        })),
      );
    });
  };

  const listGroup = () => {
    axiosInstance.get(`/products/group`).then((response) => {
      let result = response.data.response.result;
      setproductgroup_options(
        result.map((row) => ({
          label: row.group_name,
          value: row.group_id,
        })),
      );
      // setGroupHolder(response.data.response.result);
    });
  };

  const listSegment = () => {
    axiosInstance.get(`/products/segment`).then((response) => {
      let result = response.data.response.result;
      setproductsegment_options(
        result.map((row) => ({
          label: row.segment_name,
          value: row.segment_id,
        })),
      );
      // setSegmentHolder(response.data.response.result);
    });
  };

  const listWarehouse = () => {
    axiosInstance
      .get(`/inventoryreport/dropdown/warehouse`)
      .then((response) => {
        let result = response.data.response.result;
        setwarehouse_options(
          result.map((row) => ({
            label: row.warehouse_name,
            value: row.warehouse_code,
          })),
        );
        // setWarehouseHolder(response.data.response.result);
      });
  };

  const handleChangeDateRange = (e) => {
    let datefrom =
      e.target.name === 'report_date_from'
        ? e.target.value
        : formFilter.report_date_from;

    let dateto =
      e.target.name === 'report_date_to'
        ? e.target.value
        : formFilter.report_date_to;

    setFormFilter({
      ...formFilter,
      [e.target.name]: e.target.value,
    });

    if (dateto !== '') {
      if (dateto >= datefrom) {
        setVisible(false);
        setVisibleButton(true);
      } else {
        setVisible(true);
        setVisibleButton(false);
      }
    } else {
      setVisible(false);
      setVisibleButton(false);
    }
  };

  // const handleChangeTo = (e) => {
  // var dateto = e.target.valueAsDate;
  // var datefrom = values.report_date_from;

  // if (datefrom !== "") {
  //   var newdatefrom = new Date(datefrom);

  //   if (dateto >= newdatefrom) {
  //     setVisible(false);
  //     setVisibleButton(true);
  //   } else {
  //     setVisible(true);
  //     setVisibleButton(false);
  //   }
  // } else {
  //   setVisible(false);
  //   setVisibleButton(false);
  // }
  // };

  // const warehouseVerification = (retvalue) => {
  // if (retvalue == "warehouse" || retvalue == "summary") {
  //   setVisibleDiv(true);
  // } else {
  //   setVisibleDiv(false);
  // }
  // };

  const handleChangeType = (e) => {
    setFormFilter({
      ...formFilter,
      table_col: e?.value || '',
    });
  };

  const handleFilter = (e) => {
    let array = e.target.value.map((e) => e.value);

    setFormFilter({
      ...formFilter,
      [e.target.name]: array,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    // const formData = new FormData(e.target);

    // // concert formdata into JSON
    // var obj = Object.fromEntries(formData);

    // //insert multiple fields into JSON Data
    // if (formData.getAll("productname")[0] == "") {
    //   obj.product_name = [];
    // } else {
    //   obj.product_name = formData.getAll("productname");
    // }

    // if (formData.getAll("productgroup")[0] == "") {
    //   obj.product_group = [];
    // } else {
    //   obj.product_group = formData.getAll("productgroup");
    // }

    // if (formData.getAll("productsegment")[0] == "") {
    //   obj.product_segment = [];
    // } else {
    //   obj.product_segment = formData.getAll("productsegment");
    // }

    // var warehousesoption = [];
    // if (formData.getAll("warehouse_f")[0] == "") {
    //   obj.warehouse = [];
    // } else {
    //   obj.warehouse = formData.getAll("warehouse_f");
    //   warehousesoption = formData.getAll("warehouse_f");
    // }

    setLoader(true);
    toast.loading('Please wait generating report ...');

    if (formFilter.table_col === 'warehouse') {
      axiosInstance
        .post(`/inventoryreport/inventorysummarywarehouse`, formFilter)
        .then((res) => {
          var objres = {
            warehouses: warehouse_options,
            objoutput: JSON.parse(JSON.stringify(res)),
          };

          history.push({
            pathname: '/view/summary_warehouse/inventoryreport',
            state: objres,
          });

          setLoader(false);
          toast.dismiss();
        });
    } else if (formFilter.table_col === 'movement') {
      axiosInstance
        .post(`/inventoryreport/inventorymovementsummary`, formFilter)
        .then((res) => {
          // var objres = JSON.parse(JSON.stringify(res.data.response.result));

          history.push({
            pathname: '/view/summary_warehouse/inventoryreport/movement',
            state: res.data.response.result,
          });

          setLoader(false);
          toast.dismiss();
        });
    } else {
      axiosInstance
        .post(`/inventoryreport/inventorysummary`, formFilter)
        .then((res) => {
          var objres = JSON.parse(JSON.stringify(res));

          history.push({
            pathname: '/view/summary/inventoryreport',
            state: objres,
          });

          setLoader(false);
          toast.dismiss();
        });
    }
  };

  const generateInventoryMovementReport = () => {};

  // prodNameHolder.map((prodname) => {
  //   productname_options.push({
  //     label: prodname.product_name,
  //     value: prodname.product_code,
  //   });
  // });

  // groupHolder.map((holdername) => {
  //   productgroup_options.push({
  //     label: holdername.group_name,
  //     value: holdername.group_id,
  //   });
  // });

  // segmentHolder.map((segmentname) => {
  //   productsegment_options.push({
  //     label: segmentname.segment_name,
  //     value: segmentname.segment_id,
  //   });
  // });

  // warehouseHolder.map((warehousename) => {
  //   warehouse_options.push({
  //     label: warehousename.warehouse_name,
  //     value: warehousename.warehouse_code,
  //   });
  // });

  const tablecolumns_options = [
    { label: 'Summary', value: 'summary' },
    { label: 'Warehouse', value: 'warehouse' },
    // { label: "Inventory Movement", value: "movement" },
  ];

  return (
    <Fragment>
      <PrimaryLoader visible={loader} />
      <DefaultLayout title="Inventory Report">
        <Tabs
          id="controlled-tab-example"
          activeKey="home"
          className="default-tab"
          onSelect={(key) => console.log(key)}
        >
          <Tab
            eventKey="home"
            title="Inventory Report"
            className="default-tab-content"
          >
            <form onSubmit={submitHandler}>
              <div className="">
                <div className="row">
                  <div className="col-md-12 mt-2">
                    <div className="alert alert-info">
                      <strong>Note:</strong> Leave the Products or Warehouse
                      fields blank/empty to trigger <strong>Select All</strong>
                      {''} function.
                    </div>
                    {visible && (
                      <div id="daterange_notif" className="alert alert-danger">
                        &nbsp;
                        <strong>Error:</strong> Invalid date range.
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Product Name</Form.Label>
                      <Select
                        isMulti
                        name="product_name"
                        options={productname_options}
                        menuPlacement="auto"
                        placeholder="ALL"
                        menuPosition="fixed"
                        onChange={(e) =>
                          handleFilter({
                            target: {
                              name: 'product_name',
                              value: e,
                            },
                          })
                        }
                      />
                    </Form.Group>
                    {/*  */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Product Group</Form.Label>
                      <Select
                        name="product_group"
                        multiple
                        options={productgroup_options}
                        isMulti="true"
                        menuPlacement="auto"
                        placeholder="ALL"
                        menuPosition="fixed"
                        onChange={(e) =>
                          handleFilter({
                            target: {
                              name: 'product_group',
                              value: e,
                            },
                          })
                        }
                      />
                    </Form.Group>
                    {/*  */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Product Segment</Form.Label>
                      <Select
                        name="product_segment"
                        multiple
                        options={productsegment_options}
                        isMulti="true"
                        menuPlacement="auto"
                        placeholder="ALL"
                        menuPosition="fixed"
                        onChange={(e) =>
                          handleFilter({
                            target: {
                              name: 'product_segment',
                              value: e,
                            },
                          })
                        }
                      />
                    </Form.Group>
                    {/*  */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Warehouse </Form.Label>
                      <Select
                        name="warehouse"
                        multiple
                        options={warehouse_options}
                        isMulti="true"
                        menuPlacement="auto"
                        placeholder="ALL"
                        menuPosition="fixed"
                        onChange={(e) =>
                          handleFilter({
                            target: {
                              name: 'warehouse',
                              value: e,
                            },
                          })
                        }
                      />
                    </Form.Group>
                    {/*  */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Change Table Columns * </Form.Label>
                      <Select
                        name="table_col"
                        options={tablecolumns_options}
                        onChange={handleChangeType}
                        placeholder="Select Report Type"
                        isClearable
                      />
                    </Form.Group>
                  </div>
                </div>

                {formFilter.table_col !== '' && (
                  <div className="row">
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label> Report Date From </Form.Label>
                        <input
                          type="date"
                          name="report_date_from"
                          className="form-control input-icon"
                          value={formFilter.report_date_from}
                          onChange={handleChangeDateRange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label> Report Date To </Form.Label>
                        <input
                          type="date"
                          name="report_date_to"
                          className="form-control input-icon"
                          value={formFilter.report_date_to}
                          onChange={handleChangeDateRange}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}

                {visiblebutton && (
                  <div className="row">
                    <div className="col-md-6 text-right">
                      <button type="submit" className="btn btn-success">
                        Generate
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </Tab>
        </Tabs>
      </DefaultLayout>
    </Fragment>
  );
};

export default InventoryReport;
