import React, { Fragment, useEffect, useState } from 'react';
import './styles/modal.css';

import axiosInstance from '../../../axiosInstance';
import Swal from 'sweetalert2';
import CustomerShippingAddresses from './ViewCustomerShippingAddresses';
import Select from 'react-select';

import {
  DropdownProvince,
  DropdownCities,
  DropdownBarangays,
  DropdownZipCodes,
} from '../../../Addresses';

import useFormSelect2 from '../../../../useFormSelect2';
import PrimaryToolTip from './PrimaryToolTip';
import PrimaryInputCurrency from '../../../PrimaryInputCurrency';
import CustomerDeliveryTime from './CustomerDeliveryTime';
import DropdownCustom from './billing-shipping/DropdownCustom';

const defaultShippingAddressForm = {
  shipping_bldg_street_address: '',
  shipping_barangay: '',
  shipping_city: '',
  shipping_province: '',
  shipping_zip_code: '',
  shipping_lba_code: '',
  shipping_lba_name: '',
  shipping_district: '',
};
const CreateCustomer = ({ index }) => {
  const [values, setValues] = useState({
    customer_name: '',
    customer_type: '',
    customer_channel: '',
    customer_tagging: '',
    regular_or_nonregular: '',
    tagged_or_owned: 0,
    tagged_or_owned_collections: [],

    ewt: 0,
    sc_discount: 0,
    payment_terms: '',
    initial_stocking_terms: '',
    vat_type: '',
    po_based: 0,
    credit_limit: 0,
    return_policy: '',
    with_agreed_charges: 0,
    sc_booking: 0,
    allow_water_injection: 0,
    charges_collections: [],
    charges_others_checkbox: 0,
    charges_others_text_field: '',
    AO: '',
    tin_no: '',
    business_style: '',
    business_type: '',
    owner_name: '',
    owner_email: '',
    owner_contact_no: '',
    customer_mds: [],
    accounting_contact_no: '',
    accounting_contact_person: '',
    accounting_designation: '',
    accounting_email: '',
    purchasing_contact_person: '',
    purchasing_contact_no: '',
    purchasing_designation: '',
    purchasing_email: '',
    secretary_contact_person: '',
    secretary_contact_no: '',
    secretary_designation: '',
    secretary_email: '',
    authorized_purchase_person_contact_person: '',
    authorized_purchase_person_contact_no: '',
    authorized_purchase_person_designation: '',
    authorized_purchase_person_email: '',
    authorized_receive_person_contact_person: '',
    authorized_receive_person_contact_no: '',
    authorized_receive_person_designation: '',
    authorized_receive_person_email: '',
    pharmacy_contact_no: '',
    pharmacy_contact_person: '',
    pharmacy_designation: '',
    pharmacy_email: '',
    billing_mode_of_payment: '',
    billing_bldg_street_address: '',
    billing_barangay: '',
    billing_city: '',
    billing_province: '',
    billing_zip_code: '',
    billing_lba_code: '',
    billing_lba_name: '',
    billing_district: '',
    //shipping_delivery_schedule_day: [],

    shipping_mon_start: '',
    shipping_mon_end: '',
    shipping_tue_start: '',
    shipping_tue_end: '',
    shipping_wed_start: '',
    shipping_wed_end: '',
    shipping_thur_start: '',
    shipping_thur_end: '',
    shipping_fri_start: '',
    shipping_fri_end: '',
    shipping_sat_start: '',
    shipping_sat_end: '',
    shipping_sun_start: '',
    shipping_sun_end: '',

    //shipping_delivery_time_start: "",
    //shipping_delivery_time_end: "",
    shipping_delivery_service_provider: '',
    shipping_bldg_street_address: '',
    shipping_barangay: '',
    shipping_city: '',
    shipping_province: '',
    shipping_zip_code: '',
    shipping_lba_code: '',
    shipping_lba_name: '',
    shipping_district: '',
    shipping_addresses: [defaultShippingAddressForm],
    remarks: '',
    is_submit: 2,
    sar: '',
    hsar: '',
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [authPersonToPurchase, setAuthPersonToPurchase] = useState(false);
  const [authPersonToReceive, setAuthPersonToReceive] = useState(false);

  // const [showDays, setShowDays] = useState([]);

  // const loadDays = () => {
  //   const days = [
  //     {
  //       day_id: 1,
  //       day: "Mon",
  //     },
  //     {
  //       day_id: 2,
  //       day: "Tue",
  //     },
  //     {
  //       day_id: 3,
  //       day: "Wed",
  //     },
  //     {
  //       day_id: 4,
  //       day: "Thu",
  //     },
  //     {
  //       day_id: 5,
  //       day: "Fri",
  //     },
  //     {
  //       day_id: 6,
  //       day: "Sat",
  //     },
  //     {
  //       day_id: 7,
  //       day: "Sun",
  //     },
  //   ];
  //   setShowDays(days);
  // };

  //for checkbox
  const authPersonToPurchaseCopy = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setAuthPersonToPurchase(true);
      setValues({
        ...values,
        authorized_purchase_person_contact_person:
          values.purchasing_contact_person,
        authorized_purchase_person_contact_no: values.purchasing_contact_no,
        authorized_purchase_person_contact_no_1: values.purchasing_contact_no_1,
        authorized_purchase_person_designation: values.purchasing_designation,
        authorized_purchase_person_email: values.purchasing_email,
      });
    } else {
      setAuthPersonToPurchase(false);
      setValues({
        ...values,
        authorized_purchase_person_contact_person: '',
        authorized_purchase_person_contact_no: '',
        authorized_purchase_person_contact_no_1: '',
        authorized_purchase_person_designation: '',
        authorized_purchase_person_email: '',
      });
    }
  };

  const authPersonToReceiveCopy = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setAuthPersonToReceive(true);
      setValues({
        ...values,
        authorized_receive_person_contact_person:
          values.accounting_contact_person,
        authorized_receive_person_contact_no: values.accounting_contact_no,
        authorized_receive_person_contact_no_1: values.accounting_contact_no_1,
        authorized_receive_person_designation: values.accounting_designation,
        authorized_receive_person_email: values.accounting_email,
      });
    } else {
      setAuthPersonToReceive(false);
      setValues({
        ...values,
        authorized_receive_person_contact_person: '',
        authorized_receive_person_contact_no: '',
        authorized_receive_person_contact_no_1: '',
        authorized_receive_person_designation: '',
        authorized_receive_person_email: '',
      });
    }
  };

  const handleChecked = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.checked ? 1 : 0,
    });
  };
  //END OF CHECKBOXES

  //multiple value of checkbox

  const handleCheckChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setValues({
        ...values,
        tagged_or_owned_collections: [
          ...values.tagged_or_owned_collections,
          event.target.value,
        ],
      });
    } else {
      setValues({
        ...values,
        tagged_or_owned_collections: [
          ...values.tagged_or_owned_collections.filter((value) => {
            return value !== event.target.value;
          }),
        ],
      });
    }
  };

  const handleChargesChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setValues({
        ...values,
        charges_collections: [
          ...values.charges_collections,
          event.target.value,
        ],
      });
    } else {
      setValues({
        ...values,
        charges_collections: [
          ...values.charges_collections.filter((value) => {
            return value !== event.target.value;
          }),
        ],
      });
    }
  };

  const handleScheduleDay = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setValues({
        ...values,
        shipping_delivery_schedule_day: [
          ...values.shipping_delivery_schedule_day,
          Number(event.target.value),
        ],
      });
    } else {
      setValues({
        ...values,
        shipping_delivery_schedule_day: [
          ...values.shipping_delivery_schedule_day.filter((value) => {
            return value !== Number(event.target.value);
          }),
        ],
      });
    }
  };

  //end multiple value
  //hide and show others

  // default 2 rows for addresses
  /*   const [shippingAddresses, setShippingAddresses] = useState([
    defaultShippingAddressForm,
    defaultShippingAddressForm,
  ]); */

  // const onAddAddress = () => {
  //   //setShippingAddresses([...shippingAddresses, defaultShippingAddressForm]);
  //   setValues({
  //     ...values,
  //     shipping_addresses: [
  //       ...values.shipping_addresses,
  //       defaultShippingAddressForm,
  //     ],
  //   });
  // };

  // const onRemoveAddress = (selectedIndex) => {
  //   setValues({
  //     ...values,
  //     shipping_addresses: [
  //       ...values.shipping_addresses.filter((address, index) => {
  //         return selectedIndex !== index;
  //       }),
  //     ],
  //   });
  // };

  const onChangeAddress = (newShippingAddress) => {
    setValues({
      ...values,
      shipping_addresses: newShippingAddress,
    });

    // setValues({
    //   ...values,
    //   shipping_addresses: [
    //     ...values.shipping_addresses.map((address, index) => {
    //       if (index === selectedIndex) {
    //         return { ...address, [name]: value };
    //       }

    //       return address;
    //     }),
    //   ],
    // });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    let mon_start_time = values.shipping_mon_start;
    let mon_end_time = values.shipping_mon_end;

    let tue_start_time = values.shipping_tue_start;
    let tue_end_time = values.shipping_tue_end;

    let wed_start_time = values.shipping_wed_start;
    let wed_end_time = values.shipping_wed_end;

    let thu_start_time = values.shipping_thu_start;
    let thu_end_time = values.shipping_thu_end;

    let fri_start_time = values.shipping_fri_start;
    let fri_end_time = values.shipping_fri_end;

    let sat_start_time = values.shipping_sat_start;
    let sat_end_time = values.shipping_sat_end;

    let sun_start_time = values.shipping_sun_start;
    let sun_end_time = values.shipping_sun_end;

    if (
      mon_start_time > mon_end_time ||
      tue_start_time > tue_end_time ||
      wed_start_time > wed_end_time ||
      thu_start_time > thu_end_time ||
      fri_start_time > fri_end_time ||
      sat_start_time > sat_end_time ||
      sun_start_time > sun_end_time
    ) {
      Swal.fire({
        icon: 'error',
        text: 'Start time must be greater than end time',
      });
      return;
    }

    data.append('ewt', values.ewt);
    data.append('tagged_or_owned', values.tagged_or_owned);
    data.append('with_agreed_charges', values.with_agreed_charges);
    data.append('sc_booking', values.sc_booking);
    data.append('allow_water_injection', values.allow_water_injection);
    data.append('sc_discount', values.sc_discount);
    data.append('po_based', values.po_based);
    data.append('hsar', values.hsar);
    data.append('sar', values.sar);

    data.append('charges_others_checkbox', values.charges_others_checkbox);
    data.append('charges_others_text_field', values.charges_others_text_field);

    data.append(
      'authorized_purchase_person_contact_person',
      values.authorized_purchase_person_contact_person,
    );
    data.append(
      'authorized_purchase_person_contact_no',
      values.authorized_purchase_person_contact_no,
    );
    data.append(
      'authorized_purchase_person_designation',
      values.authorized_purchase_person_designation,
    );
    data.append(
      'authorized_purchase_person_email',
      values.authorized_purchase_person_email,
    );

    data.append(
      'authorized_receive_person_contact_person',
      values.authorized_receive_person_contact_person,
    );
    data.append(
      'authorized_receive_person_contact_no',
      values.authorized_receive_person_contact_no,
    );
    data.append(
      'authorized_receive_person_designation',
      values.authorized_receive_person_designation,
    );
    data.append(
      'authorized_receive_person_email',
      values.authorized_receive_person_email,
    );
    axiosInstance
      .post('/customer/create', data, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) =>
        res.data.response.code === '00003'
          ? Swal.fire({
              icon: 'success',
              text: res.data.response.result,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = '/customers';
              }
            })
          : Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: Array.isArray(res.data.response.result)
                ? res.data.response.result.join('<br />')
                : res.data.response.result,
            }),
      );
  };

  const [showTab, setTab] = useState('tab1');

  const [customerTypeHolder, setCustomerTypeHolder] = useState([]);
  const [customerChannelListHolder, setCustomerChannelListHolder] = useState(
    [],
  );
  const [
    customerChannelIdentificationHolder,
    setCustomerChannelIdentificationHolder,
  ] = useState([]);
  const [regularNonRegularListHolder, setRegularNonRegularListHolder] =
    useState([]);
  const [vatTypeHolder, setVatTypeHolder] = useState([]);
  const [returnPolicyHolder, setReturnPolicyHolder] = useState([]);
  const [businessTypeHolder, setBusinessTypeHolder] = useState([]);

  const [mopHolder, setMopHolder] = useState([]);
  const [paymentHolder, setPaymentHolder] = useState([]);
  const [stockingHolder, setStockingHolder] = useState([]);
  const [courierHolder, setCourierHolder] = useState([]);
  const [mdListHolder, setMdListHolder] = useState([]);
  const [aoDataHolder, setAODataHolder] = useState([]);

  useEffect(() => {
    let componentMounted = true;

    const loadCustomerType = async () => {
      const res = await axiosInstance.get(`/list/customer-type`, {
        method: 'GET',
      });
      if (componentMounted) {
        setCustomerTypeHolder(res.data.response.result);
      }
    };

    const loadCustomerChannelList = async () => {
      const res = await axiosInstance.get(`/list/customer-channel`, {
        method: 'GET',
      });
      if (componentMounted) {
        setCustomerChannelListHolder(res.data.response.result);
      }
    };

    const loadCustomerIdentification = async () => {
      const res = await axiosInstance.get(`/customers/tagging`, {
        method: 'GET',
      });

      if (componentMounted) {
        setCustomerChannelIdentificationHolder(res.data.result);
      }
    };

    const loadRegularNonRegularList = async () => {
      const res = await axiosInstance.get(
        `/list/customer-regular-non-regular`,
        {
          method: 'GET',
        },
      );
      if (componentMounted) {
        setRegularNonRegularListHolder(res.data.response.result);
      }
    };

    const loadVatType = async () => {
      const res = await axiosInstance.get(`/list/vat-type`, {
        method: 'GET',
      });
      if (componentMounted) {
        setVatTypeHolder(res.data.response.result);
      }
    };

    const loadReturnPolicy = async () => {
      const res = await axiosInstance.get(`/list/customer-return-policy`, {
        method: 'GET',
      });
      if (componentMounted) {
        setReturnPolicyHolder(res.data.response.result);
      }
    };

    const loadBusinessType = async () => {
      const res = await axiosInstance.get(`/list/customer-business-type`, {
        method: 'GET',
      });
      if (componentMounted) {
        setBusinessTypeHolder(res.data.response.result);
      }
    };

    const loadMop = async () => {
      const res = await axiosInstance.get(`/payments/mode`, {
        method: 'GET',
      });
      if (componentMounted) {
        setMopHolder(res.data.response.result);
      }
    };

    const loadPayment = async () => {
      const res = await axiosInstance.get(`/payments/term`, {
        method: 'GET',
      });
      if (componentMounted) {
        const { result } = res.data.response;
        setPaymentHolder(result);
      }
    };

    const loadInitialStocking = async () => {
      const res = await axiosInstance.get(`/initial_stocking/term`, {
        method: 'GET',
      });
      if (componentMounted) {
        const { result } = res.data.response;
        setStockingHolder(result);
      }
    };

    const loadCourier = async () => {
      const res = await axiosInstance.get(`/couriers`, {
        method: 'GET',
      });
      if (componentMounted) {
        setCourierHolder(res.data.response.result);
      }
    };

    const loadMds = async () => {
      const res = await axiosInstance.get(`/search/customer/mds`, {
        method: 'GET',
      });
      if (componentMounted) {
        setMdListHolder(res.data.response.result);
      }
    };

    const loadAOData = async () => {
      let tmp = [];

      const res = await axiosInstance.get(`/customer/ao/all`, {
        method: 'GET',
      });
      if (componentMounted) {
        const { result, code } = res.data.response;

        if (code === '00000') {
          result.map((row) => {
            tmp.push({
              value: row.ao_id,
              label: row.ao_name,
            });
            console.log({ tmp: tmp });
          });
          setAODataHolder(tmp);
        }
      }
    };

    loadCustomerType();
    loadCustomerChannelList();
    loadRegularNonRegularList();
    loadVatType();
    loadReturnPolicy();
    loadBusinessType();
    loadMop();
    loadPayment();
    loadInitialStocking();
    loadCourier();
    loadMds();
    loadAOData();
    loadCustomerIdentification();

    //loadDays();

    return () => {
      componentMounted = false;
    };
  }, []);

  /* Associate MDS */
  const [mdValue, handleMdValue] = useFormSelect2({
    search: '',
  });

  const searchOptionHolder = [];

  const [searchHolder, setSearchHolder] = useState([]);

  const searchMD = (e) => {
    e.preventDefault();
    const keyword = mdValue.search.value;
    if (!keyword) {
      alert('Select cannot be empty');
      return;
    }
    axiosInstance
      .post('/search/customer/md', {
        keyword: keyword,
      })
      .then((res) => {
        //console.log(res);
        setSearchHolder(res.data.response.result);
      });
  };

  // eslint-disable-next-line no-lone-blocks
  {
    mdListHolder.map((md) => {
      searchOptionHolder.push({
        value: md.md_code,
        label: md.md_code + ' - ' + md.md_profile.first_name,
      });
    });
  }

  // const onTest = () => {
  //   let mon_start_time = values.shipping_mon_start;
  //   let mon_end_time = values.shipping_mon_end;

  //   let tue_start_time = values.shipping_tue_start;
  //   let tue_end_time = values.shipping_tue_end;

  //   let wed_start_time = values.shipping_wed_start;
  //   let wed_end_time = values.shipping_wed_end;

  //   let thu_start_time = values.shipping_thu_start;
  //   let thu_end_time = values.shipping_thu_end;

  //   let fri_start_time = values.shipping_fri_start;
  //   let fri_end_time = values.shipping_fri_end;

  //   let sat_start_time = values.shipping_sat_start;
  //   let sat_end_time = values.shipping_sat_end;

  //   let sun_start_time = values.shipping_sun_start;
  //   let sun_end_time = values.shipping_sun_end;

  //   if (
  //     mon_start_time > mon_end_time ||
  //     tue_start_time > tue_end_time ||
  //     wed_start_time > wed_end_time ||
  //     thu_start_time > thu_end_time ||
  //     fri_start_time > fri_end_time ||
  //     sat_start_time > sat_end_time ||
  //     sun_start_time > sun_end_time
  //   ) {
  //     console.log("Start time must be greater than end time");
  //     return;
  //   }
  //   console.log({ values });
  // };

  return (
    <Fragment>
      <div className="container-fluid">
        <form id="handler-form" onSubmit={submitHandler} noValidate>
          <div className="card-tabs mt-2">
            <div className="mb-2">
              <button
                type="submit"
                className="btn btn-info"
                style={{ float: 'right' }}
              >
                Create Customer
              </button>
            </div>
            <ul className="nav nav-tabs bg-header">
              <li className="nav-item">
                <span
                  className={showTab == 'tab1' ? 'nav-link active' : 'nav-link'}
                  data-toggle="tab"
                  onClick={() => {
                    setTab('tab1');
                  }}
                >
                  Customer Information
                </span>
              </li>
              {/* Hiding Md And Sales Tab */}
              {/*   <li className="nav-item">
                <span
                  className={showTab == "tab2" ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  onClick={() => {
                    setTab("tab2");
                   
                  }}
                >
                  MD & Sales
                </span>
              </li> */}
              <li className="nav-item">
                <span
                  className={showTab == 'tab3' ? 'nav-link active' : 'nav-link'}
                  data-toggle="tab"
                  onClick={() => {
                    setTab('tab3');
                  }}
                >
                  Business Contacts
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={showTab == 'tab4' ? 'nav-link active' : 'nav-link'}
                  data-toggle="tab"
                  onClick={() => {
                    setTab('tab4');
                  }}
                >
                  Billing & Shipping
                </span>
              </li>
            </ul>
            <div className="tab-content" style={{ minHeight: '60vh' }}>
              <div
                id="tab-top-1"
                className={
                  showTab == 'tab1'
                    ? 'card tab-pane tab-height active show'
                    : 'card tab-pane tab-height '
                }
              >
                {/* Customer Information */}
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="row">
                      <fieldset className="details-field-set mb-4 mt-0">
                        <legend>Customer Account Information</legend>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Customer Account Name
                                    <span className="requiredField"> *</span>
                                  </label>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="customer_name"
                                    id="customer_name"
                                    value={values.customer_name}
                                    onChange={handleChange}
                                  />
                                  <input
                                    type="hidden"
                                    className="form-control form-control-sm"
                                    name="is_submit"
                                    id="is_submit"
                                    value={2}
                                  />
                                </div>
                              </div>
                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Customer Type
                                    <span className="requiredField"> *</span>
                                  </label>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <DropdownCustom
                                    data={customerTypeHolder}
                                    valueField={'customer_type_id'}
                                    nameField={'customer_type_desc'}
                                    selected={
                                      parseInt(values.customer_type) || 0
                                    }
                                    placeholder={'- Select Customer Type -'}
                                    name={'customer_type'}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Customer Channel
                                    <span className="requiredField"> *</span>
                                  </label>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <DropdownCustom
                                    data={customerChannelListHolder}
                                    valueField={'customer_channel_id'}
                                    nameField={'channel'}
                                    selected={
                                      parseInt(values.customer_channel) || 0
                                    }
                                    placeholder={'- Select Customer Channel -'}
                                    name={'customer_channel'}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}

                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Customer Tagging
                                    <span className="requiredField"> *</span>
                                  </label>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <DropdownCustom
                                    data={customerChannelIdentificationHolder}
                                    valueField={'id'}
                                    nameField={'tagging'}
                                    selected={parseInt(values.tagging || 0)}
                                    placeholder={'- Select Customer Channel -'}
                                    name={'tagging'}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}

                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    (Non) / Regular Customer
                                    <span className="requiredField"> *</span>
                                  </label>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <DropdownCustom
                                    data={regularNonRegularListHolder}
                                    valueField={
                                      'customer_regular_nonregular_id'
                                    }
                                    nameField={'description'}
                                    selected={
                                      parseInt(values.regular_or_nonregular) ||
                                      0
                                    }
                                    placeholder={
                                      '- Select Status of Customer -'
                                    }
                                    name={'regular_or_nonregular'}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm"></label>
                                </div>

                                <div className="col-lg-6">
                                  <input
                                    type="file"
                                    className="form-control form-control-sm"
                                    id="si_attachment"
                                    name="si_attachment"
                                  />
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Tagged / Owned
                                  </label>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="tagged_or_owned"
                                      id="tagged_or_owned"
                                      value={values.tagged_or_owned}
                                      onChange={handleChecked}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="defaultCheck1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm"></label>
                                </div>

                                <div className="col-lg-6">
                                  {values.tagged_or_owned === 1 ? (
                                    <fieldset className="details-field-set">
                                      <legend>
                                        <label className="col-form-label col-form-label-sm">
                                          {' '}
                                          Collection for Tagged/Owned
                                        </label>
                                      </legend>

                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="tagged_or_owned_collections[]"
                                          id="tagged_or_owned_collections"
                                          value={'Collection c/o Account'}
                                          onChange={handleCheckChange}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="defaultCheck1"
                                        >
                                          Collection c/o Account
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="tagged_or_owned_collections[]"
                                          id="tagged_or_owned_collections"
                                          value={'Collection c/o Associate MD'}
                                          onChange={handleCheckChange}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="defaultCheck1"
                                        >
                                          Collection c/o Associate MD
                                        </label>
                                      </div>
                                    </fieldset>
                                  ) : null}
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    AO
                                  </label>
                                  <span className="requiredField"> *</span>
                                  <PrimaryToolTip />
                                </div>

                                {/* <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="AO"
                                    id="AO"
                                    value={values.AO}
                                    onChange={handleChange}
                                  />
                                </div> */}

                                <div className="col-lg-6">
                                  <Select
                                    options={aoDataHolder}
                                    placeholder="- List Of AO -"
                                    name="AO"
                                    value={
                                      values.AO !== ''
                                        ? aoDataHolder.find(
                                            (row) =>
                                              row.value === values.AO?.ao_id,
                                          )
                                        : ''
                                    }
                                    onChange={(e) =>
                                      handleChange({
                                        target: { name: 'AO', value: e.value },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              {/*  */}
                            </div>
                            <div className="col-md-6">
                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm"></label>
                                </div>

                                <div className="col-lg-6">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="ewt"
                                          id="ewt"
                                          value={values.ewt}
                                          onChange={handleChecked}
                                        />
                                        <label className="form-check-label">
                                          EWT
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-8">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="sc_discount"
                                          id="sc_discount"
                                          value={values.sc_discount}
                                          onChange={handleChecked}
                                        />
                                        <label className="form-check-label">
                                          Senior Citizen Discount
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Vat Type
                                  </label>
                                  <span className="requiredField"> *</span>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <DropdownCustom
                                    data={vatTypeHolder}
                                    valueField={'vat_type_id'}
                                    nameField={'type'}
                                    selected={parseInt(values.vat_type) || 0}
                                    placeholder={'- Select Vat Type -'}
                                    name={'vat_type'}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}
                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    PO-Based
                                  </label>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="po_based"
                                      id="po_based"
                                      value={values.po_based}
                                      onChange={handleChecked}
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/*  */}
                              {/*  */}
                              {values.po_based === 1 ? (
                                <div className="row">
                                  <div className="col-sm-4">
                                    <label className="col-form-label col-form-label-sm"></label>
                                  </div>
                                  <div className="col-sm-6 mt-1">
                                    <input
                                      type="file"
                                      className="form-control form-control-sm"
                                      id="po_attachment"
                                      name="po_attachment"
                                    />
                                  </div>
                                </div>
                              ) : null}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Credit Limit
                                  </label>
                                  <span className="requiredField"> *</span>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <PrimaryInputCurrency
                                    name="credit_limit"
                                    value={values.credit_limit}
                                    onValueChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Return Policy
                                  </label>
                                  <span className="requiredField"> *</span>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <DropdownCustom
                                    data={returnPolicyHolder}
                                    valueField={'customer_return_policy_id'}
                                    nameField={'policy'}
                                    selected={
                                      parseInt(values.return_policy) || 0
                                    }
                                    placeholder={'- Select Policy -'}
                                    name={'return_policy'}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    With Agreed Charges
                                  </label>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="with_agreed_charges"
                                      id="with_agreed_charges"
                                      value={values.with_agreed_charges}
                                      onChange={handleChecked}
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/*  */}
                              <div className="row">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm"></label>
                                </div>
                                <div className="col-lg-6">
                                  {values.with_agreed_charges === 1 ? (
                                    <fieldset className="details-field-set">
                                      <legend>
                                        <label className="col-form-label col-form-label-sm">
                                          Charges
                                        </label>
                                      </legend>
                                      <div className="form-check ">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="charges_collections[]"
                                          id="charges_collections"
                                          value={'Senior Citizen Discount'}
                                          onChange={handleChargesChange}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="defaultCheck1"
                                        >
                                          Senior Citizen Discount
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="charges_collections[]"
                                          id="charges_collections"
                                          value={'Distribution Allowance'}
                                          onChange={handleChargesChange}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="defaultCheck1"
                                        >
                                          Distribution Allowance
                                        </label>

                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="charges_collections[]"
                                          id="charges_collections"
                                          value={'Store Opening Support'}
                                          onChange={handleChargesChange}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="defaultCheck1"
                                        >
                                          Store Opening Support
                                        </label>

                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="charges_collections[]"
                                          id="charges_collections"
                                          value={'Scanned Data Fee'}
                                          onChange={handleChargesChange}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="defaultCheck1"
                                        >
                                          Scanned Data Fee
                                        </label>

                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="charges_others_checkbox"
                                          id="charges_others_checkbox"
                                          value={values.charges_others_checkbox}
                                          onChange={handleChecked}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="defaultCheck1"
                                        >
                                          Others
                                        </label>
                                        <input
                                          className="form-control form-control-sm"
                                          type="text"
                                          name="charges_others_text_field"
                                          id="charges_others_text_field"
                                          value={
                                            values.charges_others_text_field
                                          }
                                          onChange={handleChange}
                                          disable={
                                            !values.charges_others_checkbox
                                          }
                                        />
                                      </div>
                                    </fieldset>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <fieldset className="details-field-set mb-4 mt-0">
                        <legend>Business Information</legend>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              {/*  */}
                              <div className="row">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    TIN Number
                                  </label>

                                  <span className="requiredField"> *</span>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="tin_no"
                                    id="tin_no"
                                    value={values.tin_no}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Business Style
                                  </label>
                                  {values.customer_type != 4 ? (
                                    <span className="requiredField"> *</span>
                                  ) : null}
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="business_style"
                                    id="business_style"
                                    value={values.business_style}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Business Type
                                  </label>
                                  <span className="requiredField"> *</span>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <DropdownCustom
                                    data={businessTypeHolder}
                                    valueField={'customer_business_type_id'}
                                    nameField={'business_type'}
                                    selected={
                                      parseInt(values.business_type) || 0
                                    }
                                    placeholder={'- Select Business Type -'}
                                    name={'business_type'}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}
                            </div>
                            <div className="col-lg-6">
                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Owner Name
                                  </label>

                                  <span className="requiredField"> *</span>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="owner_name"
                                    id="owner_name"
                                    value={values.owner_name}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Owner Email
                                  </label>

                                  <span className="requiredField"> *</span>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <input
                                    type="email"
                                    className="form-control form-control-sm"
                                    name="owner_email"
                                    id="owner_email"
                                    value={values.owner_email}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Owner Contact Number
                                  </label>

                                  <span className="requiredField"> *</span>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="owner_contact_no"
                                    id="owner_contact_no"
                                    value={values.owner_contact_no}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {/*  */}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <fieldset className="details-field-set mb-4 mt-0">
                        <legend>Booking Configuration</legend>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Allow FG/SC Booking
                                  </label>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="sc_booking"
                                      id="sc_booking"
                                      value={values.sc_booking}
                                      onChange={handleChecked}
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/*  */}
                            </div>
                            <div className="col-md-6">
                              {/*  */}
                              <div className="row mt-2">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Allow Water for Injection
                                  </label>
                                  <PrimaryToolTip />
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="allow_water_injection"
                                      id="allow_water_injection"
                                      value={values.allow_water_injection}
                                      onChange={handleChecked}
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/*  */}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              {/* Customer Information */}

              {/* Business Contacts */}
              <div
                id="tab-top-3"
                className={
                  showTab == 'tab3'
                    ? 'card tab-pane tab-height active show'
                    : 'card tab-pane tab-height '
                }
              >
                <div className="card-body">
                  <fieldset className="details-field-set mb-4 mt-0">
                    <legend>Business Contacts</legend>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          {values.customer_type != 4 ? (
                            <div className="row">
                              <fieldset className="details-field-set mb-4 mt-2">
                                <legend>Account Contact Information</legend>
                                {/*  */}
                                <div className="row pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm"></label>
                                  </div>
                                  <div className="col-lg-6"></div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Contact Person
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="accounting_contact_person"
                                      id="accounting_contact_person"
                                      value={values.accounting_contact_person}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Contact Number
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="accounting_contact_no"
                                      id="accounting_contact_no"
                                      value={values.accounting_contact_no}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                {/* <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm"></label>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="accounting_contact_no_1"
                                  id="accounting_contact_no_1"
                                  value={values.accounting_contact_no_1}
                                  onChange={handleChange}
                                />
                              </div>
                            </div> */}
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Designation
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="accounting_designation"
                                      id="accounting_designation"
                                      value={values.accounting_designation}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Email
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="email"
                                      className="form-control form-control-sm"
                                      name="accounting_email"
                                      id="accounting_email"
                                      value={values.accounting_email}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                              </fieldset>
                              <fieldset className="details-field-set mb-4 mt-2">
                                <legend>Purchasing Contact Information</legend>
                                {/*  */}
                                <div className="row pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm"></label>
                                  </div>
                                  <div className="col-lg-6"></div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Contact Person
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="purchasing_contact_person"
                                      id="purchasing_contact_person"
                                      value={values.purchasing_contact_person}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Contact Number
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="purchasing_contact_no"
                                      id="purchasing_contact_no"
                                      value={values.purchasing_contact_no}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                {/* <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm"></label>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="purchasing_contact_no_1"
                                  id="purchasing_contact_no_1"
                                  value={values.purchasing_contact_no_1}
                                  onChange={handleChange}
                                />
                              </div> 
                            </div> */}
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Designation
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="purchasing_designation"
                                      id="purchasing_designation"
                                      value={values.purchasing_designation}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Email
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="email"
                                      className="form-control form-control-sm"
                                      name="purchasing_email"
                                      id="purchasing_email"
                                      value={values.purchasing_email}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                              </fieldset>
                              <fieldset className="details-field-set mb-4 mt-2">
                                <legend>Pharmacy Contact Information</legend>
                                {/*  */}
                                <div className="row pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm"></label>
                                  </div>
                                  <div className="col-lg-6"></div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Contact Person
                                    </label>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="pharmacy_contact_person"
                                      id="pharmacy_contact_person"
                                      value={values.pharmacy_contact_person}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Contact Number
                                    </label>

                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="pharmacy_contact_no"
                                      id="pharmacy_contact_no"
                                      value={values.pharmacy_contact_no}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                {/* <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm"></label>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="pharmacy_contact_no_1"
                                  id="pharmacy_contact_no_1"
                                  value={values.pharmacy_contact_no_1}
                                  onChange={handleChange}
                                />
                              </div>
                            </div> */}
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Designation
                                    </label>

                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="pharmacy_designation"
                                      id="pharmacy_designation"
                                      value={values.pharmacy_designation}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Email
                                    </label>

                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="email"
                                      className="form-control form-control-sm"
                                      name="pharmacy_email"
                                      id="pharmacy_email"
                                      value={values.pharmacy_email}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                              </fieldset>
                            </div>
                          ) : (
                            <div className="row">
                              <fieldset className="details-field-set mb-4 mt-2">
                                <legend>Secretary Contact Information</legend>

                                {/*  */}
                                <div className="row pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Contact Person
                                    </label>

                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="secretary_contact_person"
                                      id="secretary_contact_person"
                                      value={values.secretary_contact_person}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Contact Number
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="secretary_contact_no"
                                      id="secretary_contact_no"
                                      value={values.secretary_contact_no}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                {/* <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm"></label>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="secretary_contact_no_1"
                                  id="secretary_contact_no_1"
                                  value={values.secretary_contact_no_1}
                                  onChange={handleChange}
                                />
                              </div>
                            </div> */}
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Designation
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      name="secretary_designation"
                                      id="secretary_designation"
                                      value={values.secretary_designation}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {/*  */}
                                <div className="row mt-2 pl-3">
                                  <div className="col-lg-4">
                                    <label className="col-form-label col-form-label-sm">
                                      Email
                                    </label>
                                    <span className="requiredField"> *</span>
                                    <PrimaryToolTip />
                                  </div>
                                  <div className="col-lg-6">
                                    <input
                                      type="email"
                                      className="form-control form-control-sm"
                                      name="secretary_email"
                                      id="secretary_email"
                                      value={values.secretary_email}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                              </fieldset>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <fieldset className="details-field-set mb-4 mt-2">
                            <legend>Authorized Person To Purchase</legend>

                            {/*  */}
                            {values.customer_type != 4 ? (
                              <div className="row pl-3">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Same with Purchase Info?
                                  </label>

                                  <PrimaryToolTip />
                                </div>
                                <div className="col-lg-6">
                                  <input
                                    type="checkbox"
                                    name="authPersonToPurchase"
                                    id="authPersonToPurchase"
                                    onChange={authPersonToPurchaseCopy}
                                    disabled={!values.purchasing_contact_person}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {/*  */}
                            {/*  */}
                            <div className="row pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm">
                                  Contact Person
                                </label>
                                <span className="requiredField"> *</span>
                                <PrimaryToolTip />
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="authorized_purchase_person_contact_person"
                                  id="authorized_purchase_person_contact_person"
                                  value={
                                    values.authorized_purchase_person_contact_person
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            {/*  */}
                            {/*  */}
                            <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm">
                                  Contact Number
                                </label>
                                <span className="requiredField"> *</span>
                                <PrimaryToolTip />
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="authorized_purchase_person_contact_no"
                                  id="authorized_purchase_person_contact_no"
                                  value={
                                    values.authorized_purchase_person_contact_no
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            {/*  */}
                            {/*  */}
                            {/* <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm"></label>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="authorized_purchase_person_contact_no_1"
                                  id="authorized_purchase_person_contact_no_1"
                                  value={
                                    values.authorized_purchase_person_contact_no_1
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div> */}
                            {/*  */}
                            {/*  */}
                            <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm">
                                  Designation
                                </label>
                                <span className="requiredField"> *</span>
                                <PrimaryToolTip />
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="authorized_purchase_person_designation"
                                  id="authorized_purchase_person_designation"
                                  value={
                                    values.authorized_purchase_person_designation
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            {/*  */}
                            {/*  */}
                            <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm">
                                  Email
                                </label>
                                <span className="requiredField"> *</span>
                                <PrimaryToolTip />
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="email"
                                  className="form-control form-control-sm"
                                  name="authorized_purchase_person_email"
                                  id="authorized_purchase_person_email"
                                  value={
                                    values.authorized_purchase_person_email
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            {/*  */}
                          </fieldset>

                          <fieldset className="details-field-set mb-4 mt-2">
                            <legend>Authorized Person To Received</legend>
                            {/*  */}
                            {values.customer_type != 4 ? (
                              <div className="row pl-3">
                                <div className="col-lg-4">
                                  <label className="col-form-label col-form-label-sm">
                                    Same with Account Info?
                                  </label>
                                  <PrimaryToolTip />
                                </div>
                                <div className="col-lg-6">
                                  <input
                                    type="checkbox"
                                    name="isCopyAuthPersonToReceive"
                                    id="isCopyAuthPersonToReceive"
                                    onChange={authPersonToReceiveCopy}
                                    disabled={!values.accounting_contact_person}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {/*  */}
                            {/*  */}
                            <div className="row pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm">
                                  Contact Person
                                </label>
                                <span className="requiredField"> *</span>
                                <PrimaryToolTip />
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="authorized_receive_person_contact_person"
                                  id="authorized_receive_person_contact_person"
                                  value={
                                    values.authorized_receive_person_contact_person
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            {/*  */}
                            {/*  */}
                            <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm">
                                  Contact Number
                                </label>
                                <span className="requiredField"> *</span>
                                <PrimaryToolTip />
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="authorized_receive_person_contact_no"
                                  id="authorized_receive_person_contact_no"
                                  value={
                                    values.authorized_receive_person_contact_no
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            {/*  */}
                            {/*  */}
                            {/* <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm"></label>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="authorized_receive_person_contact_no_1"
                                  id="authorized_receive_person_contact_no_1"
                                  value={
                                    values.authorized_receive_person_contact_no_1
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div> */}
                            {/*  */}
                            {/*  */}
                            <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm">
                                  Designation
                                </label>
                                <span className="requiredField"> *</span>
                                <PrimaryToolTip />
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="authorized_receive_person_designation"
                                  id="authorized_receive_person_designation"
                                  value={
                                    values.authorized_receive_person_designation
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            {/*  */}
                            {/*  */}
                            <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm">
                                  Email
                                </label>
                                <span className="requiredField"> *</span>
                                <PrimaryToolTip />
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="email"
                                  className="form-control form-control-sm"
                                  name="authorized_receive_person_email"
                                  id="authorized_receive_person_email"
                                  value={values.authorized_receive_person_email}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            {/*  */}
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              {/* Business Contacts */}
              {/* Billing and Shipping */}
              <div
                id="tab-top-4"
                className={
                  showTab == 'tab4'
                    ? 'card tab-pane tab-height active show'
                    : 'card tab-pane tab-height '
                }
              >
                <div className="card-body">
                  <fieldset className="details-field-set mb-4 mt-0">
                    <legend>Billing</legend>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          {/*  */}
                          <div className="row">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                Mode Of Payment
                                <span className="requiredField"> *</span>
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <DropdownCustom
                                data={mopHolder}
                                valueField={'payment_mode_id'}
                                nameField={'payment_mode'}
                                selected={
                                  parseInt(values.billing_mode_of_payment) || 0
                                }
                                placeholder={'- Select Mode of Payment -'}
                                name={'billing_mode_of_payment'}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*  */}
                        </div>

                        <div className="col-md-6">
                          {/*  */}
                          <div className="row">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                Payment Terms
                                <span className="requiredField"> *</span>
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <DropdownCustom
                                data={paymentHolder}
                                valueField={'payment_terms_id'}
                                nameField={'payment_terms'}
                                selected={parseInt(values.payment_terms) || 0}
                                placeholder={'- Select Mode of Payment -'}
                                name={'payment_terms'}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*  */}
                          {/*  */}
                          <div className="row mt-2">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                Initial Stocking
                                <span className="requiredField"> *</span>
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <DropdownCustom
                                data={stockingHolder}
                                valueField={'payment_terms_id'}
                                nameField={'payment_terms'}
                                selected={
                                  parseInt(values.initial_stocking_terms) || 6
                                }
                                placeholder={'- Select Initial Stocking -'}
                                name={'initial_stocking_terms'}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*  */}
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset className="details-field-set mb-4 mt-0">
                    <legend>Billing Address</legend>
                    <div className="col-md-12">
                      <div className="row">
                        {/*  */}
                        <div className="col-lg-2">
                          <label className="col-form-label col-form-label-sm">
                            Bldg & Street Address
                            <span className="requiredField"> *</span>
                          </label>
                          <PrimaryToolTip />
                        </div>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="billing_bldg_street_address"
                            id="billing_bldg_street_address"
                            value={values.billing_bldg_street_address}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/*  */}
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          {/*  */}
                          <div className="row mt-2">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                Province
                                <span className="requiredField"> *</span>
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <DropdownProvince
                                classWrapper="form-control form-control-sm"
                                name={'billing_province'}
                                value={values.billing_province}
                                onChange={(value) =>
                                  setValues({
                                    ...values,
                                    billing_province: value,
                                    billing_city: '',
                                    billing_barangay: '',
                                    billing_zip_code: '',
                                  })
                                }
                              />
                            </div>
                          </div>
                          {/*  */}
                          {/*  */}
                          <div className="row mt-2">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                City
                                <span className="requiredField"> *</span>
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <DropdownCities
                                classWrapper={'form-control form-control-sm'}
                                provinceId={values.billing_province}
                                name="billing_city"
                                value={values.billing_city}
                                onChange={(value) =>
                                  setValues({
                                    ...values,
                                    billing_city: value,
                                    billing_barangay: '',
                                    billing_zip_code: '',
                                  })
                                }
                              />
                            </div>
                          </div>
                          {/*  */}
                          {/*  */}
                          <div className="row mt-2">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                Barangay
                                <span className="requiredField"> *</span>
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <DropdownBarangays
                                classWrapper={'form-control form-control-sm'}
                                cityId={values.billing_city}
                                name="billing_barangay"
                                value={values.billing_barangay}
                                onChange={(value) =>
                                  setValues({
                                    ...values,
                                    billing_barangay: value,
                                    billing_zip_code: '',
                                  })
                                }
                              />
                            </div>
                          </div>
                          {/*  */}
                          {/*  */}
                          <div className="row mt-2">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                Zip Code
                                <span className="requiredField"> *</span>
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <DropdownZipCodes
                                classWrapper={'form-control form-control-sm'}
                                cityId={values.billing_city}
                                name="billing_zip_code"
                                value={values.billing_zip_code}
                                onChange={(value) =>
                                  setValues({
                                    ...values,
                                    billing_zip_code: value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          {/*  */}
                        </div>

                        <div className="col-md-6">
                          {/*  */}
                          <div className="row mt-2">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                LBA Code
                                <span className="requiredField"> *</span>
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="billing_lba_code"
                                id="billing_lba_code"
                                value={values.billing_lba_code}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*  */}
                          {/*  */}
                          <div className="row mt-2">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                LBA Name
                                <span className="requiredField"> *</span>
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="billing_lba_name"
                                id="billing_lba_name"
                                value={values.billing_lba_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*  */}
                          {/*  */}
                          <div className="row mt-2">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                District
                                <span className="requiredField"> *</span>
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="billing_district"
                                id="billing_district"
                                value={values.billing_district}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*  */}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="details-field-set mb-4 mt-0">
                    <legend>Shipping</legend>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <CustomerDeliveryTime
                            values={values}
                            handleChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          {/*  */}
                          <div className="row mt-2">
                            <div className="col-lg-4">
                              <label className="col-form-label col-form-label-sm">
                                Dist. Service Provider
                              </label>
                              <PrimaryToolTip />
                            </div>
                            <div className="col-lg-6">
                              <DropdownCustom
                                data={courierHolder}
                                valueField={'courier_id'} //courier_id
                                nameField={'company_name'}
                                selected={
                                  parseInt(
                                    values.shipping_delivery_service_provider,
                                  ) || 0
                                }
                                placeholder={'- Select Courier -'}
                                name={'shipping_delivery_service_provider'}
                                onChange={handleChange}
                              />

                              {/* <select
                                className="form-control form-control-sm"
                                name="shipping_delivery_service_provider"
                                id="shipping_delivery_service_provider"
                                value={
                                  values.shipping_delivery_service_provider
                                }
                                onChange={handleChange}
                              >
                                <option value={0} disable selected>
                                  Select Courier
                                </option>
                                {courierHolder.map((courier) => (
                                  <option
                                    key={courier.courier_code}
                                    value={courier.courier_code}
                                  >
                                    {courier.company_name}
                                  </option>
                                ))}
                              </select> */}
                            </div>
                          </div>
                          {/*  */}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <CustomerShippingAddresses
                    data={values.shipping_addresses}
                    onChangeAddress={onChangeAddress}
                  />
                </div>
              </div>
              {/* Billing and Shipping */}
            </div>
          </div>
          {/* <button
            type="button"
            className="btn btn-primary"
            onClick={() => onTest()}
          >
            Test Submit
          </button> */}
        </form>
      </div>
    </Fragment>
  );
};

export default CreateCustomer;
