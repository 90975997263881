import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import * as rootReducer from './reducers';

const persistConfig = {
  key: 'bk_root',
  storage,
  whitelist: ['auth', 'menu', 'journalVoucher'],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_REDUX_PERSIST_SECRET_KEY,
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const reducers = combineReducers({
  ...rootReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, ...getDefaultMiddleware({ serializableCheck: false })],
  devTools: true,
});

const persistor = persistStore(store);

export { persistor, store };
