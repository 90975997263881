import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PrimaryLoader from '../../../PrimaryLoader';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import { HddFill, PencilSquare, People } from 'react-bootstrap-icons';

import './styles/modal.css';

import axiosInstance from '../../../axiosInstance';

import CustomerInfo from './customer-info/CustomerInfo';
import CustomerMD from './md/CustomerMD';
import BusinessContacts from './contacts/BusinessContacts';
import Billing from './billing-shipping/Billing';
import Shipping from './billing-shipping/Shipping';

/* For Switch */
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Switch } from '@material-ui/core';
import { updateCustomer } from '../../../../api/customer';
import ViewCustomerCreditAssessment from './ViewCustomerCreditAssessment';
import CustomerProductAndDiscounts from './CustomerProductAndDiscounts';
import CustomerDocuments from './CustomerDocuments';
import CustomerSalesInvoiceTemplateSetup from './CustomerSalesInvoiceTemplateSetup';
import CustomerBranches from './CustomerBranches';

import CreateCustomer from './CreateCustomer';
import SideBarSearch from '../../../SideBarSearch';
import { Modal } from 'react-bootstrap';
import useNavActive from '../../../Navbar/useNavActive';
import debounce from 'lodash.debounce';
/* EndFor Switch */
// Lodash
import _ from 'lodash';
import defaultOptions from '../../../../utils/defaultOptions';
import moment from 'moment';
import { AlertDefaultError, PermissionsGate } from '../../../PermissionsGate';
import { getCustomersList } from '../../../../api/customer';
import CustomerArInformation from './CustomerArInformation';
import PrimarySwitch from '../../../PrimarySwitch';
/* Switch */
const GreenSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);
/* End Switch */

const defaultShippingAddressForm = {
  customer_shipping_address_id: '',
  shipping_bldg_street_address: '',
  shipping_barangay: '',
  shipping_city: '',
  shipping_province: '',
  shipping_zip_code: '',
  shipping_lba_code: '',
  shipping_lba_name: '',
  shipping_district: '',
};
const defaultForm = {
  customer_name: '',
  customer_code: '',
  customer_type: '',
  business_trade_name: '',
  registered_name: '',
  billing_bldg_street_address: '',
  billing_lba_code: '',
  billing_lba_name: '',
  billing_district: '',
  billing_province: '',
  billing_barangay: '',
  billing_city: '',
  billing_zip_code: '',
  shipping_delivery_schedule_day: [],
  shipping_delivery_service_provider: '',
  shipping_mon_start: '',
  shipping_mon_end: '',
  shipping_tue_start: '',
  shipping_tue_end: '',
  shipping_wed_start: '',
  shipping_wed_end: '',
  shipping_thur_start: '',
  shipping_thur_end: '',
  shipping_fri_start: '',
  shipping_fri_end: '',
  shipping_sat_start: '',
  shipping_sat_end: '',
  shipping_sun_start: '',
  shipping_sun_end: '',
  shipping_addresses: [],
  tagged_or_owned: 0,
  po_based: 0,
  ewt: 0,
  sc_discount: 0,
  with_agreed_charges: 0,
  sc_booking: 0,
  allow_water_injection: 0,
  charges_collections: [],
  charges_others_checkbox: 0,
  charges_others_text_field: '',
  tagged_or_owned_collections: [],
  is_submit: 2,
  accounting_contact_person: '',
  accounting_contact_no: '',
  accounting_designation: '',
  accounting_email: '',

  purchasing_contact_person: '',
  purchasing_contact_no: '',
  purchasing_designation: '',
  purchasing_email: '',

  secretary_contact_person: '',
  secretary_contact_no: '',
  secretary_designation: '',
  secretary_email: '',

  authorized_purchase_person_contact_person: '',
  authorized_purchase_person_contact_no: '',
  authorized_purchase_person_designation: '',
  authorized_purchase_person_email: '',

  authorized_receive_person_contact_person: '',
  authorized_receive_person_contact_no: '',
  authorized_receive_person_designation: '',
  authorized_receive_person_email: '',

  pharmacy_contact_person: '',
  pharmacy_contact_no: '',
  pharmacy_designation: '',
  pharmacy_email: '',

  si_attachment: '',
  po_attachment: '',
  si_filename: '',
  po_filename: '',
  ca_flag: [],
};

const ViewCustomer = () => {
  useNavActive(['master_databases', 'customer_list']);

  const [customersListData, setCustomersListData] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [authPersonToPurchase, setAuthPersonToPurchase] = useState(false);
  const [authPersonToReceive, setAuthPersonToReceive] = useState(false);

  const styles = {
    border: {
      border: '1px solid #e7eaef',
    },
    divPadding: {
      padding: '5px',
    },
    fieldset: {
      border: '1px solid rgb(230 232 233)',
      position: 'relative',
      padding: '0 10px 10px',
    },
    legend: {
      position: 'absolute',
      top: '-10px',
      left: '1%',
      fontSize: '13px',
      background: '#fff',
      color: '#000',
      display: 'unset',
      maxWidth: 'unset',
      width: 'unset',
      padding: '0 15px',
    },

    formGroup: {
      margin: 0,
      paddingTop: '0px',
      paddingLeft: '0px',
      paddingBottom: '0px',
      paddingRight: '0',
    },
  };

  const [customerDetails, setCustomerDetails] = useState({});

  const { customer_code } = useParams();

  const [form, setForm] = useState(defaultForm); // sya input data

  const [loading, setLoading] = useState(false);
  // Added by charles for products and discounts
  const [productsDiscounts, setProductsDiscounts] = useState([]);

  const [sideBarLoader, setSideBarLoader] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState([]);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const [creditAssessment, setCreditAssessment] = useState([]);

  useEffect(() => {
    let componentMounted = true;

    const loadCustomers = async () => {
      const res = await axiosInstance.get('/customer/list', {
        method: 'GET',
      });

      if (componentMounted) {
        setCustomersListData(res.data.response.result);
      }
    };

    const loadCreditAssessment = async () => {
      const res = await axiosInstance.post('/customer/credit/assessment', {
        customer_code: customer_code,
        date: moment().format('YYYY-MM-DD'),
      });
      if (componentMounted) {
        setCreditAssessment(res.data.response.result);
      }
    };

    const getCustomerDetails = async () => {
      setLoading(true);
      const res = await axiosInstance.get(`/customer/${customer_code}`, {
        method: 'GET',
      });

      if (componentMounted) {
        let details = res.data.response.result;

        setCustomerDetails(details);

        mapFormData(details);
        if (details.status === 1) {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
      }

      setTimeout(() => setLoading(false), 3000);
    };
    loadCustomers();

    loadCreditAssessment();

    getCustomerDetails();

    populateCustomer();

    return () => {
      componentMounted = false;
    };
  }, [customer_code]);

  const [showTab, setTab] = useState('tab1');
  const [updateForm, setUpdateForm] = useState(false);

  const onUpdateForm = () => {
    setUpdateForm(!updateForm);

    //when cancelling update form, field value must revert back to its old value
    mapFormData(customerDetails);
  };

  const mapData = (customerData) => {
    let tmpObj = {};

    if (customerData.business_contacts.accounting.length) {
      tmpObj = {
        accounting_contact_person:
          customerData.business_contacts.accounting[0].contact_person,
        accounting_contact_no:
          customerData.business_contacts.accounting[0].contact_no,
        accounting_designation:
          customerData.business_contacts.accounting[0].designation,
        accounting_email: customerData.business_contacts.accounting[0].email,
      };
    }
    if (customerData.business_contacts.purchasing.length) {
      tmpObj = {
        ...tmpObj,
        purchasing_contact_person:
          customerData.business_contacts.purchasing[0].contact_person,
        purchasing_contact_no:
          customerData.business_contacts.purchasing[0].contact_no,
        purchasing_designation:
          customerData.business_contacts.purchasing[0].designation,
        purchasing_email: customerData.business_contacts.purchasing[0].email,
      };
    }

    if (customerData.business_contacts.secretary.length) {
      tmpObj = {
        ...tmpObj,
        secretary_contact_person:
          customerData.business_contacts.secretary[0].contact_person,
        secretary_contact_no:
          customerData.business_contacts.secretary[0].contact_no,

        secretary_designation:
          customerData.business_contacts.secretary[0].designation,
        secretary_email: customerData.business_contacts.secretary[0].email,
      };
    }

    if (customerData.business_contacts.authorized_purchase_person.length) {
      tmpObj = {
        ...tmpObj,
        authorized_purchase_person_contact_person:
          customerData.business_contacts.authorized_purchase_person[0]
            .contact_person,
        authorized_purchase_person_contact_no:
          customerData.business_contacts.authorized_purchase_person[0]
            .contact_no,
        authorized_purchase_person_designation:
          customerData.business_contacts.authorized_purchase_person[0]
            .designation,
        authorized_purchase_person_email:
          customerData.business_contacts.authorized_purchase_person[0].email,
      };
    }

    if (customerData.business_contacts.authorized_receive_person.length) {
      tmpObj = {
        ...tmpObj,
        authorized_receive_person_contact_person:
          customerData.business_contacts.authorized_receive_person[0]
            .contact_person,
        authorized_receive_person_contact_no:
          customerData.business_contacts.authorized_receive_person[0]
            .contact_no,
        authorized_receive_person_designation:
          customerData.business_contacts.authorized_receive_person[0]
            .designation,
        authorized_receive_person_email:
          customerData.business_contacts.authorized_receive_person[0].email,
      };
    }

    if (customerData.business_contacts.pharmacy.length) {
      tmpObj = {
        ...tmpObj,
        pharmacy_contact_person:
          customerData.business_contacts.pharmacy[0].contact_person ?? '',
        pharmacy_contact_no:
          customerData.business_contacts.pharmacy[0].contact_no ?? '',
        pharmacy_designation:
          customerData.business_contacts.pharmacy[0].designation ?? '',
        pharmacy_email: customerData.business_contacts.pharmacy[0].email ?? '',
      };
    }

    return tmpObj;
  };

  const attachmentHolder = (customerData) => {
    let attachmentHolder = {
      si_attachment: '',
      po_attachment: '',
    };

    if (customerData.si_attachment !== null) {
      attachmentHolder = {
        ...attachmentHolder,
        si_attachment: customerData.si_attachment.raw_path,
      };
    }

    if (customerData.po_attachment !== null) {
      attachmentHolder = {
        ...attachmentHolder,
        po_attachment: customerData.po_attachment.raw_path,
      };
    }
    return attachmentHolder;
  };

  const fileHolder = (customerData) => {
    let fileHolder = {
      si_filename: '',
      po_filename: '',
    };

    if (customerData.si_attachment !== null) {
      fileHolder = {
        ...fileHolder,
        si_filename: customerData.si_attachment.file_name,
      };
    }

    if (customerData.po_attachment !== null) {
      fileHolder = {
        ...fileHolder,
        po_filename: customerData.po_attachment.file_name,
      };
    }
    return fileHolder;
  };

  const mapFormData = (customerData) => {
    // set shipping and billing data

    let payment_terms_id = null;
    let initial_stocking_terms_id = null;

    if (customerData.billing.length > 0) {
      payment_terms_id = customerData.billing[0].payment_terms.payment_terms_id;
    }

    if (customerData.billing.length > 0) {
      initial_stocking_terms_id =
        customerData.billing[0].initial_stocking_terms.payment_terms_id;
    }

    let billingShippingData = mapBillingShipping(customerData);
    let allData = mapData(customerData);
    let attachments = attachmentHolder(customerData);
    let filenames = fileHolder(customerData);
    let tmp = {
      customer_name: customerData.customer_name,
      customer_code: customerData.customer_code,
      customer_type: customerData.customer_type.customer_type_id,
      customer_channel: customerData.customer_channel.customer_channel_id,
      customer_tagging: customerData?.customer_tagging?.id,
      kam_name: customerData.kam_code,
      kass_name: customerData.kass_code,
      sar: customerData.sar_code,
      hsar: customerData.hsar_code,
      segment_manager: customerData.segment_manager_code,
      regular_or_nonregular:
        customerData?.regular_or_nonregular?.customer_regular_nonregular_id,
      tagged_or_owned: customerData.tagged_or_owned,

      vat_type: customerData.vat_type,
      return_policy: customerData.return_policy.customer_return_policy_id,
      business_style: customerData.business_style ?? '',
      business_type: customerData.business_type.customer_business_type_id,
      business_trade_name: customerData.business_trade_name,
      registered_name: customerData.registered_name,

      //tagged_or_owned_collections: [],

      ewt: customerData.ewt,
      sc_discount: customerData.sc_discount,
      //  payment_terms: customerData.billing.payment_terms.payment_terms_id,
      payment_terms: payment_terms_id,
      initial_stocking_terms: initial_stocking_terms_id,
      //

      po_based: customerData.po_based,
      credit_limit: customerData.credit_limit,
      min_order_required: customerData.min_order_required,

      with_agreed_charges: customerData.with_agreed_charges,
      sc_booking: customerData.sc_booking,
      allow_water_injection: customerData.allow_water_injection,
      AO: customerData.AO?.ao_id,
      tin_no: customerData.tin_no,
      owner_name: customerData.owner_name,
      owner_email: customerData.owner_email,
      owner_contact_no: customerData.owner_contact_no,
      charges_collections: customerData.charges_collections,
      tagged_or_owned_collections: customerData.tagged_or_owned_collections,
      charges_others_checkbox: customerData.charges_others_checkbox,
      charges_others_text_field: customerData.charges_others_text_field,
      is_submit: 2,
      ca_flag: customerData.ca_flag,
      ...billingShippingData,
      ...allData,
      ...attachments,
      ...filenames,
    };

    setForm({ ...form, ...tmp });
  };

  const mapBillingShipping = (customerData) => {
    let tmp = { shipping_addresses: [] };
    if (customerData.billing.length) {
      const billing = customerData.billing[0];

      tmp.billing_mode_of_payment = billing.mode_of_payment.payment_mode_id;
      tmp.billing_payment_terms = billing.payment_terms.payment_terms_id;
      tmp.billing_initial_stocking =
        billing.initial_stocking_terms.payment_terms_id;

      if (billing.billing_address.length) {
        const billing_address = billing.billing_address[0];
        tmp.customer_billing_address_id =
          billing_address.customer_billing_address_id;

        tmp.billing_bldg_street_address = billing_address.bldg_street_address;
        tmp.billing_lba_code = billing_address.lba_code;
        tmp.billing_lba_name = billing_address.lba_name;
        tmp.billing_district = billing_address.district;
        tmp.billing_province = billing_address.province;

        tmp.billing_barangay = billing_address.barangay.barangay_id;
        tmp.billing_city = billing_address.city.city_id;
        tmp.billing_province = billing_address.province.province_id;
        tmp.billing_zip_code = billing_address.zip_code.id;
      }
    }

    if (customerData.shipping.length) {
      const shipping = customerData.shipping[0];

      if (shipping.delivery_service_provider !== null) {
        tmp.shipping_delivery_service_provider =
          shipping.delivery_service_provider.courier_code;
      }
      tmp.shipping_mon_start = shipping.delivery_mon_start;
      tmp.shipping_mon_end = shipping.delivery_mon_end;
      tmp.shipping_tue_start = shipping.delivery_tue_start;
      tmp.shipping_tue_end = shipping.delivery_tue_end;
      tmp.shipping_wed_start = shipping.delivery_wed_start;
      tmp.shipping_wed_end = shipping.delivery_wed_end;
      tmp.shipping_thur_start = shipping.delivery_thur_start;
      tmp.shipping_thur_end = shipping.delivery_thur_end;
      tmp.shipping_fri_start = shipping.delivery_fri_start;
      tmp.shipping_fri_end = shipping.delivery_fri_end;
      tmp.shipping_sat_start = shipping.delivery_sat_start;
      tmp.shipping_sat_end = shipping.delivery_sat_end;
      tmp.shipping_sun_start = shipping.delivery_sun_start;
      tmp.shipping_sun_end = shipping.delivery_sun_end;

      // tmp.shipping_delivery_schedule_day = shipping.delivery_schedule_day;

      // tmp.shipping_delivery_time_start = shipping.delivery_time_start;
      // tmp.shipping_delivery_time_end = shipping.delivery_time_end;

      if (shipping.shipping_address.length) {
        shipping.shipping_address.map((row) => {
          tmp.shipping_addresses.push({
            customer_shipping_address_id: row.customer_shipping_address_id,
            shipping_bldg_street_address: row.bldg_street_address,
            shipping_barangay: row.barangay.barangay_id,
            shipping_city: row.city.city_id,
            shipping_province: row.province.province_id,
            shipping_zip_code: row.zip_code.id,
            shipping_lba_code: row.lba_code,
            shipping_lba_name: row.lba_name,
            shipping_district: row.district,
          });
        });
      }
    }

    return tmp;
  };

  // handle all input changes in components
  const handleChangeInput = (e) => {
    console.log({ e, handleChangeInput: 1 });
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  //copy when checked
  const authPersonToReceiveFunction = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setAuthPersonToReceive(true);
      setForm({
        ...form,
        authorized_receive_person_contact_person:
          form.accounting_contact_person,
        authorized_receive_person_contact_no: form.accounting_contact_no,
        authorized_receive_person_contact_no_1: form.accounting_contact_no_1,
        authorized_receive_person_designation: form.accounting_designation,
        authorized_receive_person_email: form.accounting_email,
      });
    } else {
      setAuthPersonToReceive(false);
      setForm({
        ...form,
        authorized_receive_person_contact_person: '',
        authorized_receive_person_contact_no: '',
        authorized_receive_person_contact_no_1: '',
        authorized_receive_person_designation: '',
        authorized_receive_person_email: '',
      });
    }
  };

  const authPersonToPurchaseFunction = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setAuthPersonToPurchase(true);
      setForm({
        ...form,
        authorized_purchase_person_contact_person:
          form.purchasing_contact_person,
        authorized_purchase_person_contact_no: form.purchasing_contact_no,
        authorized_purchase_person_contact_no_1: form.purchasing_contact_no_1,
        authorized_purchase_person_designation: form.purchasing_designation,
        authorized_purchase_person_email: form.purchasing_email,
      });
    } else {
      setAuthPersonToPurchase(false);
      setForm({
        ...form,
        authorized_purchase_person_contact_person: '',
        authorized_purchase_person_contact_no: '',
        authorized_purchase_person_contact_no_1: '',
        authorized_purchase_person_designation: '',
        authorized_purchase_person_email: '',
      });
    }
  };
  // const handleSubmitTest = () => {
  //   console.log({ form });
  // };

  const handleSave = () => {
    //setUpdateForm(!updateForm);

    // NOTE: not sure kung bakit may dalawang payment terms na galing sa get at kung ano yung tama talaga
    // kaya pinagisa ko nlng yung value

    let mon_start_time = form.shipping_mon_start;
    let mon_end_time = form.shipping_mon_end;

    let tue_start_time = form.shipping_tue_start;
    let tue_end_time = form.shipping_tue_end;

    let wed_start_time = form.shipping_wed_start;
    let wed_end_time = form.shipping_wed_end;

    let thur_start_time = form.shipping_thur_start;
    let thur_end_time = form.shipping_thur_end;

    let fri_start_time = form.shipping_fri_start;
    let fri_end_time = form.shipping_fri_end;

    let sat_start_time = form.shipping_sat_start;
    let sat_end_time = form.shipping_sat_end;

    let sun_start_time = form.shipping_sun_start;
    let sun_end_time = form.shipping_sun_end;

    //let start_time = form.shipping_delivery_time_start;
    //let end_time = form.shipping_delivery_time_end;

    if (
      mon_start_time > mon_end_time ||
      tue_start_time > tue_end_time ||
      wed_start_time > wed_end_time ||
      thur_start_time > thur_end_time ||
      fri_start_time > fri_end_time ||
      sat_start_time > sat_end_time ||
      sun_start_time > sun_end_time
    ) {
      Swal.fire({
        icon: 'error',
        text: 'Start time must be greater than end time',
      });
      return;
    }
    let formData = {
      ...form,
      billing_payment_terms: form.payment_terms,
      initial_stocking: form.initial_stocking,
      //products_discounts: productsDiscounts, //approved by sir ricky
      customer_documents: documents, // Added by charles, Products and discounts
    };

    Swal.fire({
      title: 'System Update',
      text: 'Save changes to customer?',
      showCancelButton: true,
    }).then((result) => {
      let { isConfirmed } = result;

      if (isConfirmed) {
        toast.loading('Please wait saving form ...');
        updateCustomer(formData, true).then((res) => {
          setTimeout(() => toast.dismiss(), 5000);
          res.data.response.code === '00004'
            ? Swal.fire({
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: true,
              }).then((result) => {
                setTimeout(() => toast.dismiss(), 5000);
                if (result.isConfirmed) {
                  //history.push('/customers')

                  window.location.reload();
                }
              })
            : Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: Array.isArray(res.data.response.result)
                  ? res.data.response.result.join('<br />')
                  : res.data.response.result,
              });
        });
      }
    });
  };

  const history = useHistory();
  const checkStatus = ({ value }) => {
    const status = value; //e.target.checked;

    if (!status) {
      Swal.fire({
        title: 'Do you want to deactivate this customer?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          setIsChecked(false);
          let data = {
            customer_code: form.customer_code,
          };

          axiosInstance
            .put(`/customer/deactivate`, data, {})
            .then((response) => {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.response.result,
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  history.push(`/view/customer/${form.customer_code}`);
                }
              });
            });
        } else {
          setIsChecked(true);
        }
      });
    } else {
      Swal.fire({
        title: 'Do you want to activate this customer?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          setIsChecked(true);
          let data = {
            customer_code: form.customer_code,
          };

          axiosInstance.put(`/customer/activate`, data, {}).then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.response.result,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push(`/view/customer/${form.customer_code}`);
              }
            });
          });
        } else {
          setIsChecked(false);
        }
      });
    }
  };

  const onChangeAddress = (newShippingAddress) => {
    setForm({
      ...form,
      shipping_addresses: newShippingAddress,
    });
  };

  const handleChecked = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked ? 1 : 0,
    });
  };

  const handleCheckboxCollections = ({ e, fieldId }) => {
    let { name, value, checked } = e.target;
    let collections = form[name];

    if (checked) {
      let tmpValues = form[name].map((row) => row.description.toLowerCase());

      // check if not already on the list
      if (tmpValues.indexOf(value.toLowerCase()) === -1) {
        // check if there is same value from the api
        let row = customerDetails[name].find(
          (row) => row.description.toLowerCase() === value.toLowerCase(),
        );

        if (!row) {
          row = { [fieldId]: '', description: value };
        }

        collections.push(row);
      }
    } else {
      // remove from the collections
      collections = collections.filter(
        (row) => row.description.toLowerCase() !== value.toLowerCase(),
      );
    }

    setForm({ ...form, [name]: collections });
  };

  const handleShippingScheduleDay = ({ target }) => {
    let { name, value, checked } = target;
    let tmpData = form.shipping_delivery_schedule_day;

    if (checked) {
      // check if already on the tmpData
      const exist = tmpData.find((row) => row === value);
      if (!exist) {
        tmpData.push(value);
      }
    } else {
      //remove sa field
      tmpData = tmpData.filter((row) => row !== value);
    }

    setForm({ ...form, shipping_delivery_schedule_day: tmpData });
  };

  const handleFileUpload = (e) => {
    setForm({ ...form, [e.target.name]: e.target.files });
  };

  // Addess by charles
  const handleProductDiscount = (products) => {
    setProductsDiscounts(products);
    setForm({ ...form, products_discounts: productsDiscounts });
  };

  const populateCustomer = (data) => {
    setSideBarLoader(true);
    getCustomersList(data)
      .then((res) => {
        const { code, result } = res.data.response;
        if (code === '00000') {
          setSearchCustomer(result);
          setSideBarLoader(false);
        }
      })
      .catch((err) => {});
  };

  const handlePopulateSearchCustomer = useCallback(
    debounce((data) => populateCustomer(data), 1000),
    [],
  );

  const populateSearchCustomerDetails = (e) => {
    let data = {
      page: 0,
      page_size: defaultOptions.pageSize,
      order_by: 'customer_code',
      direction: 'asc',
      search: e.target.value,
      column: 'customer_name',
    };
    handlePopulateSearchCustomer(data);
  };

  const labelField = {
    code: 'customer_code',
    name: 'customer_name',
    link: '/view/customer/',
  };

  const handleScheduleDay = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setForm({
        ...form,
        shipping_delivery_schedule_day: [
          ...form.shipping_delivery_schedule_day,
          Number(event.target.value),
        ],
      });
    } else {
      setForm({
        ...form,
        shipping_delivery_schedule_day: [
          ...form.shipping_delivery_schedule_day.filter((value) => {
            return value !== event.target.value;
          }),
        ],
      });
    }
  };

  return (
    <Fragment>
      <div className="container-fluid mt-3">
        <div className="col-md-12">
          <div className="row position-relative">
            <div
              className="col-lg-2 p-0 side-selector"
              style={{ fontSize: '12px' }}
            >
              <span className="side-selector-arrow">
                <People color="white" size={40} />
              </span>
              <PermissionsGate
                scopes={['get_all_customers']}
                RenderError={AlertDefaultError}
              >
                <SideBarSearch
                  title="Customer List"
                  data={searchCustomer}
                  onSearch={populateSearchCustomerDetails}
                  labelField={labelField}
                  sideBarLoader={sideBarLoader}
                  oldData={customerDetails}
                  values={form}
                  moduleName="customers"
                />
              </PermissionsGate>

              <PermissionsGate scopes={['add_customer']}>
                <div className="mt-2">
                  <button
                    className="btn btn-info btn-md btn-block"
                    onClick={() => setShowCustomerModal(true)}
                  >
                    Add Customer
                  </button>
                </div>
              </PermissionsGate>
            </div>

            <div className="col-lg-10 stretch-content-1024">
              <div className="container-fluid mb-5">
                {/* Switch */}
                <div className="row">
                  <div className="col-md-8">
                    {!customersListData ? null : (
                      <h5 className="float-left">
                        {customerDetails.customer_code} -{' '}
                        {customerDetails.customer_name}
                      </h5>
                    )}

                    <PermissionsGate
                      scopes={[
                        'customer_set_status_active',
                        'customer_set_status_deactivate',
                      ]}
                    >
                      <div style={{ float: 'left' }} className="ml-3">
                        <PrimarySwitch
                          checked={isChecked}
                          onChange={checkStatus}
                        />
                        {/* <GreenSwitch
                          checked={isChecked}
                          onChange={checkStatus}
                          disabled={!updateForm}
                        /> */}
                      </div>
                    </PermissionsGate>
                  </div>
                  <div className="col-md-4 text-right">
                    <PermissionsGate scopes={['update_customer']}>
                      {!updateForm ? (
                        <button
                          className={'btn btn-warning'}
                          onClick={onUpdateForm}
                        >
                          <PencilSquare style={{ marginTop: '-3px' }} />
                          &nbsp; Edit
                        </button>
                      ) : (
                        <button
                          className={'btn btn-success'}
                          onClick={handleSave}
                        >
                          <HddFill style={{ marginTop: '-3px' }} />
                          &nbsp; Save
                        </button>
                      )}
                    </PermissionsGate>
                  </div>
                </div>

                <div className="card-tabs mt-2 position-relative">
                  {!updateForm && showTab !== 'tab6' && showTab !== 'tab10' && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                      }}
                    ></div>
                  )}

                  <ul className="nav nav-tabs bg-header">
                    <li className="nav-item">
                      <span
                        className={
                          showTab === 'tab1' ? 'nav-link active' : 'nav-link'
                        }
                        data-toggle="tab"
                        onClick={() => {
                          setTab('tab1');
                        }}
                      >
                        Customer Information
                      </span>
                    </li>
                    <li className="nav-item">
                      <span
                        className={
                          showTab === 'tab2' ? 'nav-link active' : 'nav-link'
                        }
                        data-toggle="tab"
                        onClick={() => {
                          setTab('tab2');
                        }}
                      >
                        MD & Sales
                      </span>
                    </li>

                    <li className="nav-item">
                      <span
                        className={
                          showTab === 'tab3' ? 'nav-link active' : 'nav-link'
                        }
                        data-toggle="tab"
                        onClick={() => {
                          setTab('tab3');
                        }}
                      >
                        Business Contacts
                      </span>
                    </li>

                    <li className="nav-item">
                      <span
                        className={
                          showTab === 'tab4' ? 'nav-link active' : 'nav-link'
                        }
                        data-toggle="tab"
                        onClick={() => {
                          setTab('tab4');
                        }}
                      >
                        Billing & Shipping
                      </span>
                    </li>

                    <li className="nav-item">
                      <span
                        className={
                          showTab === 'tab5' ? 'nav-link active' : 'nav-link'
                        }
                        data-toggle="tab"
                        onClick={() => {
                          setTab('tab5');
                        }}
                      >
                        Credit Assessment
                      </span>
                    </li>

                    <li className="nav-item">
                      <span
                        className={
                          showTab === 'tab6' ? 'nav-link active' : 'nav-link'
                        }
                        data-toggle="tab"
                        onClick={() => {
                          setTab('tab6');
                        }}
                      >
                        Products & Discounts
                      </span>
                    </li>

                    <li className="nav-item">
                      <span
                        className={
                          showTab === 'tab7' ? 'nav-link active' : 'nav-link'
                        }
                        data-toggle="tab"
                        onClick={() => {
                          setTab('tab7');
                        }}
                      >
                        Documents
                      </span>
                    </li>

                    <li className="nav-item">
                      <span
                        className={
                          showTab === 'tab8' ? 'nav-link active' : 'nav-link'
                        }
                        data-toggle="tab"
                        onClick={() => {
                          setTab('tab8');
                        }}
                      >
                        SI Template
                      </span>
                    </li>

                    <li className="nav-item">
                      <span
                        className={
                          showTab === 'tab9' ? 'nav-link active' : 'nav-link'
                        }
                        data-toggle="tab"
                        onClick={() => {
                          setTab('tab9');
                        }}
                      >
                        Branches
                      </span>
                    </li>

                    <li className="nav-item">
                      <span
                        className={
                          showTab === 'tab10' ? 'nav-link active' : 'nav-link'
                        }
                        data-toggle="tab"
                        onClick={() => {
                          setTab('tab10');
                        }}
                      >
                        AR Information
                      </span>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <PrimaryLoader visible={loading} />

                    <div
                      id="tab-top-1"
                      className={
                        showTab === 'tab1'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body" style={{ minHeight: '60vh' }}>
                        <PermissionsGate
                          scopes={['get_customer']}
                          RenderError={AlertDefaultError}
                        >
                          <CustomerInfo
                            customer={customerDetails}
                            form={form}
                            handleChange={handleChangeInput}
                            handleChecked={handleChecked}
                            handleCheckboxCollections={
                              handleCheckboxCollections
                            }
                            handleFileUpload={handleFileUpload}
                          />
                        </PermissionsGate>
                      </div>
                    </div>
                    <div
                      id="tab-top-2"
                      className={
                        showTab === 'tab2'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body" style={{ minHeight: '60vh' }}>
                        <PermissionsGate
                          scopes={['get_customer']}
                          RenderError={AlertDefaultError}
                        >
                          {!_.has(form) ? (
                            <CustomerMD
                              styles={styles}
                              form={form}
                              handleChange={handleChangeInput}
                            />
                          ) : null}
                        </PermissionsGate>
                      </div>
                    </div>
                    <div
                      id="tab-top-3"
                      className={
                        showTab === 'tab3'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body" style={{ minHeight: '60vh' }}>
                        <PermissionsGate
                          scopes={['get_customer']}
                          RenderError={AlertDefaultError}
                        >
                          <BusinessContacts
                            styles={styles}
                            customer={customerDetails}
                            form={form}
                            handleChange={handleChangeInput}
                            authPersonToReceiveFunction={
                              authPersonToReceiveFunction
                            }
                            authPersonToReceive={authPersonToReceive}
                            authPersonToPurchase={authPersonToPurchase}
                            authPersonToPurchaseFunction={
                              authPersonToPurchaseFunction
                            }
                          />
                        </PermissionsGate>
                      </div>
                    </div>

                    <div
                      id="tab-top-4"
                      className={
                        showTab === 'tab4'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body" style={{ minHeight: '60vh' }}>
                        <PermissionsGate
                          scopes={['get_customer']}
                          RenderError={AlertDefaultError}
                        >
                          <Billing
                            form={form}
                            handleChange={handleChangeInput}
                          />
                          <br />
                          <Shipping
                            form={form}
                            handleChange={handleChangeInput}
                            onChangeAddress={onChangeAddress}
                            handleShippingScheduleDay={
                              handleShippingScheduleDay
                            }
                          />
                        </PermissionsGate>
                      </div>
                    </div>

                    <div
                      id="tab-top-5"
                      className={
                        showTab === 'tab5'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body" style={{ minHeight: '60vh' }}>
                        {!_.isEmpty(creditAssessment) ? (
                          <PermissionsGate
                            scopes={['get_customer']}
                            RenderError={AlertDefaultError}
                          >
                            <ViewCustomerCreditAssessment
                              customer={creditAssessment}
                              form={form}
                              setForm={setForm}
                            />
                          </PermissionsGate>
                        ) : null}
                      </div>
                    </div>

                    <div
                      id="tab-top-6"
                      className={
                        showTab === 'tab6'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body" style={{ minHeight: '60vh' }}>
                        <PermissionsGate
                          scopes={['get_customer']}
                          RenderError={AlertDefaultError}
                        >
                          <CustomerProductAndDiscounts
                            customer={customer_code}
                            handleProductDiscount={handleProductDiscount}
                            customerProductDiscount={productsDiscounts}
                          />
                        </PermissionsGate>
                      </div>
                    </div>

                    <div
                      id="tab-top-7"
                      className={
                        showTab === 'tab7'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body" style={{ minHeight: '60vh' }}>
                        <PermissionsGate
                          scopes={['get_customer']}
                          RenderError={AlertDefaultError}
                        >
                          <CustomerDocuments
                            customer={customer_code}
                            handleProductDiscount={setDocuments}
                            customerProductDiscount={documents}
                            customerDetails={customerDetails}
                            formData={form}
                          />
                        </PermissionsGate>
                      </div>
                    </div>

                    <div
                      id="tab-top-8"
                      className={
                        showTab === 'tab8'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body" style={{ minHeight: '60vh' }}>
                        <PermissionsGate
                          scopes={['get_customer']}
                          RenderError={AlertDefaultError}
                        >
                          <CustomerSalesInvoiceTemplateSetup
                            form={form}
                            setForm={setForm}
                            customerDetails={customerDetails}
                          />
                        </PermissionsGate>
                      </div>
                    </div>

                    <div
                      id="tab-top-9"
                      className={
                        showTab === 'tab9'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body" style={{ minHeight: '60vh' }}>
                        <PermissionsGate
                          scopes={['get_customer']}
                          RenderError={AlertDefaultError}
                        >
                          <CustomerBranches
                            styles={styles}
                            form={form}
                            setForm={setForm}
                            customerDetails={customerDetails}
                          />
                        </PermissionsGate>
                      </div>
                    </div>

                    <div
                      id="tab-top-10"
                      className={
                        showTab === 'tab10'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body" style={{ minHeight: '60vh' }}>
                        <CustomerArInformation
                          customerCode={customer_code}
                          customerData={customerDetails}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showCustomerModal}
        onHide={() => setShowCustomerModal(false)}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Create New Customers
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCustomer />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ViewCustomer;
