import { cloneElement } from 'react';
import { Alert } from 'react-bootstrap';
import { store } from '../../redux/store';

export const AlertDefaultError = ({
  message = "You don't have permission to access this.",
}) => {
  return <Alert variant="warning">{message}</Alert>;
};

export const PermissionsGate = ({
  children,
  errorProps, // attributes
  RenderError = () => <></>,
  scopes = [],
}) => {
  let permissionGranted = hasPermission(scopes);

  if (!permissionGranted && errorProps)
    return cloneElement(children, { ...errorProps });

  if (!permissionGranted) return <RenderError />;

  return <>{children}</>;
};

/**
 * Method for checking if user has permission for a specific target
 * @param {Array} scopes - list of permission for specific target
 *
 * @returns {Boolean}
 */

export const hasPermission = (scopes = []) => {
  const { permissions, profileData, isAuthed } = store.getState().auth;

  const scopesMap = {};

  let permissionGranted = false;

  if (!isAuthed) return false;

  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  // if empty permission list, we will add allow_all roles
  let rolesMap = { aaf1: true };
  permissionGranted = profileData.roles.some((role) => rolesMap[role]);

  if (!permissionGranted) {
    permissionGranted = permissions.some((permission) => scopesMap[permission]);
  }

  return permissionGranted;
};
