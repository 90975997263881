import Pagination from '@mui/material/Pagination';
import {
  DataGridPro,
  GridLinkOperator,
  GridToolbar,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { Fragment, useEffect, useRef, useState } from 'react';
import CustomTablePagination from './CustomTablePagination';

import _ from 'lodash';
import defaultOptions from '../../utils/defaultOptions';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const PrimaryDataTable = (props) => {
  const [pageSize, setPageSize] = useState(defaultOptions.pageSize);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(null);
  const prevReload = useRef();

  props = props.hasOwnProperty('mode')
    ? { ...props, paginationMode: 'server', sortingMode: 'server' }
    : props;

  useEffect(() => handleOnChange(pageSize, page, sort), [pageSize, page, sort]);

  // this is a hack for scrolling on top and on left side
  useEffect(() => {
    if (prevReload.current !== props.reload) {
      prevReload.current = props.reload;
      let el = document.getElementsByClassName('MuiDataGrid-window');
      if (el.length) el[0].scroll(0, 0);
    }
  }, [props?.reload]);

  const getComponents = () => {
    let components = {};

    if (props.hasOwnProperty('hasGridToolbar')) {
      components = { Toolbar: GridToolbar };
    }

    if (props.hasOwnProperty('hasCustomPagination')) {
      components = { Pagination: CustomPagination };
    }

    components = {
      ...components,
      NoRowsOverlay: () => (
        <div className="d-flex w-100 h-100 justify-content-center align-items-center position-absolute">
          No Data
        </div>
      ),
    };

    if (props.hasOwnProperty('components')) {
      components = { ...components, ...props.components };
    }

    return components;
  };

  const handleOnChange = (pageSize, page, sort) => {
    if (props.hasOwnProperty('onChange')) {
      let sortBy = {};
      // parse sort
      if (!_.isEmpty(sort)) {
        sortBy = { order_by: sort[0].field, direction: sort[0].sort };
      } else {
        sortBy = { order_by: '', direction: '' };
      }

      props.onChange({
        page_size: pageSize,
        page,
        ...sortBy,
      });
    }
  };

  const renderStyles = () => {
    let styles = { height: '680px', position: 'relative', width: '100%' };

    if (props.hasOwnProperty('wrapperHeight')) {
      styles.height = props.wrapperHeight;
    }

    if (props.hasOwnProperty('autoHeight')) {
      delete styles['height'];
    }

    // add this props if you want to not bounce dynamic rows
    if (props.hasOwnProperty('minHeight')) {
      delete styles['height'];
      styles = { ...styles, minHeight: props.minHeight };
    }

    return styles;
  };

  return (
    <Fragment>
      <div style={renderStyles()}>
        <DataGridPro
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          onPageChange={setPage}
          onSortModelChange={setSort}
          components={getComponents()}
          rowsPerPageOptions={defaultOptions.rowsPerPageOptions}
          sortingOrder={['asc', 'desc']}
          density={'compact'}
          componentsProps={{
            pagination: { ActionsComponent: CustomTablePagination },
            filterPanel: {
              linkOperators: [GridLinkOperator.And],
            },
          }}
          pagination
          //autoHeight={true}
          {...props}
        />
      </div>
    </Fragment>
  );
};

export default PrimaryDataTable;
