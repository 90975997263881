import React, { useEffect, useState, Fragment, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';

/* For Data Grid */
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme } from '@material-ui/core/styles';
import { toast } from 'react-toastify';

/* End Of data grid */

/* Modals */
import Modal from 'react-bootstrap/Modal';
/* End Of Modals */

/* Use Form to get the data */
import CreateCustomer from './views/CreateCustomer';
import { getCustomersList, exportCustomersList } from '../../../api/customer';
import { customerColumns } from './const';
import defaultOptions from '../../../utils/defaultOptions';
import { Plus } from 'react-bootstrap-icons';
import PrimaryDataTable from '../../PrimaryDataTable';
import debounce from 'lodash.debounce';
import { Dropdown, Button } from 'react-bootstrap';
import PrimaryLoader from '../../PrimaryLoader';
import { AlertDefaultError, PermissionsGate } from '../../PermissionsGate';
import PrimaryAsyncSelect from '../../PrimaryAsyncSelect';
import useNavActive from '../../Navbar/useNavActive';
/* End of Use Form to get the data */

/* SEARCH */
const defaultTheme = createTheme();
const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    textField: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      margin: theme.spacing(1, 0.5, 1.5),
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme },
);
function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          {/* <GridToolbarExport /> */}
        </GridToolbarContainer>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */
const ToolBar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
};

const Customers = () => {
  useNavActive(['master_databases', 'customer_databases', 'customer_list']);
  const [showModal, setShowModal] = useState(false);

  const [customersData, setCustomersData] = useState([]);
  const [form, setForm] = useState({
    page: 0,
    page_size: defaultOptions.pageSize,
    order_by: 'customer_code',
    direction: 'asc',
    search: '',
    column: '',
  }); // just to hold the form data

  const [tableForm, setTableForm] = useState({});
  const [total, setTotal] = useState({ rows: 0 });
  const [loading, setLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(1);
  const [pageLoader, setPageLoader] = useState(false);
  /* End of Search */

  /* End of Load Customers Data */
  const columns = customerColumns;
  const loadCustomers = async (form) => {
    setCustomersData([]);
    setLoading(true);

    try {
      let res = await getCustomersList(form);

      const { result, total_rows, code } = res.data.response;

      if (code !== '00000') {
        toast.error('Unable to load Customer List');
        return;
      }
      setCustomersData(result);

      setTotal({ rows: total_rows });
    } catch (error) {
      console.log('Error Processing Request, Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleOnDataTableChange = (data) => {
    let no_of_page = form.page_size * data.page;
    setTableForm(data);
    loadCustomers({ ...form, ...data, page: no_of_page });
  };

  const handleCustomer = useCallback(
    debounce((newFormData) => loadCustomers(newFormData), 1000),
    [],
  );

  const handleSearch = (e) => {
    let newFormData = {
      ...form,
      search: e.target.value,
    };

    setReloadTable(Math.floor(Math.random() * 101));
    handleCustomer(newFormData);

    setForm(newFormData);
  };

  const handleClearSearch = () => {
    let newFormData = { ...form, search: '' };
    setReloadTable(Math.floor(Math.random() * 101));
    loadCustomers(newFormData);
    setForm(newFormData);
  };

  const handleExport = () => {
    setPageLoader(true);
    let tmp = {
      ...form,
      export: 'export',
      ...columnVisibilityModel,
    };
    exportCustomersList({ ...tmp })
      .then((res) => {
        const { code, result } = res.data.response;
        if (code === '00000') {
          window.location.href = result;
          setPageLoader(false);
        }
      })
      .catch((err) => {
        console.log('Error Processing Request, Please try again later.');
      });
  };

  let columnSearch = [];

  columns.map((column) => {
    //if (column.hide !== true) {
    return columnSearch.push({
      value: column.field,
      label: column.headerName,
    });
    //}
  });

  const handleColumnChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const searchFunction = () => {
    loadCustomers(form);
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    customer_code: true,
    customer_name: true,
    shipping_address: false,
    lba_code: false,
    lba_name: false,
    district: true,
    tin_no: true,
    contact_person: false,
    AO: true,
    billing_address: true,
    billing_barangay: true,
    email_address: false,
    contact_no: true,
    amount_outstanding: true,
    billing_payment_mode: true,
    payment_terms_text_format: true,
    channel_text_format: true,
    customer_type_desc_text_format: false,
    past_due_rate: false,
    dso_vs_credit_terms: false,
    pay_ratio: true,
    kam_name: true,
    kass_name: true,
    sar: true,
    hsar: true,
    distribution_channel: false,
    status: true,
    ewt: true,
    sc_discount: true,
    tagging: true,
  });

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row">
              <div className="col">
                <h5>Customers Center</h5>
              </div>
            </div>
            <PermissionsGate scopes={['get_all_customers']}>
              <div
                className="white-box mb-3"
                style={{ padding: '20px 10px 0' }}
              >
                <div className="row">
                  <div className="col-md-3">
                    <label
                      htmlFor="warehouse"
                      className="col-form-label col-form-label-sm"
                    >
                      Column Header
                    </label>
                    <Select
                      options={columnSearch}
                      placeholder="-Select Column-"
                      //isClearable={true}
                      name="column"
                      onChange={(e) =>
                        handleColumnChange({
                          target: { name: 'column', value: e.value },
                        })
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <label
                      htmlFor="warehouse"
                      className="col-form-label col-form-label-sm"
                    >
                      Search Fields
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-md"
                      name="search"
                      value={form.search}
                      onChange={(e) =>
                        handleColumnChange({
                          target: {
                            name: 'search',
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  </div>

                  <div className="col-md-1">
                    <label className="col-form-label col-form-label-md"></label>
                    <div className="form-group mt-2">
                      <button
                        type="submit"
                        onClick={searchFunction}
                        className="btn btn-info"
                      >
                        Search
                      </button>
                    </div>
                  </div>

                  <div
                    className="col-md-5 text-right"
                    style={{ marginTop: '30px' }}
                  >
                    <Dropdown>
                      <PermissionsGate scopes={['customer_list_export']}>
                        <Dropdown.Toggle variant="info" id="dropdown-basic">
                          Export
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={handleExport}>
                            Download as XLSX
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </PermissionsGate>
                      <PermissionsGate scopes={['add_customer']}>
                        <Button
                          id="add_customer"
                          onClick={() => setShowModal(true)}
                          className="btn btn-success btn-pill bg-header ml-2"
                        >
                          <Plus size={20} />
                          Create New Customer
                        </Button>
                      </PermissionsGate>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </PermissionsGate>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <PrimaryLoader visible={pageLoader} />
        <div className="row">
          <div className="col-md-12">
            <PermissionsGate
              scopes={['get_all_customers']}
              RenderError={AlertDefaultError}
            >
              <PrimaryDataTable
                rows={customersData}
                columns={columns}
                getRowId={(row) => row.customer_id}
                density="compact"
                onChange={handleOnDataTableChange}
                disableColumnFilter
                rowCount={total.rows}
                loading={loading}
                mode="server"
                reload={reloadTable}
                components={{
                  Toolbar: ToolBar,
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                // components={{ Toolbar: QuickSearchToolbar }}
                // componentsProps={{
                //   toolbar: {
                //     value: form.search,
                //     onChange: handleSearch,
                //     clearSearch: handleClearSearch,
                //   },
                // }}
              />
            </PermissionsGate>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Create New Customers
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCustomer />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Customers;
