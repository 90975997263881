import { useState, useEffect } from 'react';
import SalesPerProductReportScreen from '../Screens/SalesPerProductReportScreen';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {} from '../../../api/ledgers';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
  requestSalesPerProductExport,
  requestGetSalesPerProductLedger,
  getProductsData,
} from '../../../api/ledgers';

import { numberFormatCurrency } from '../../../utils/helper';
import useNavActive from '../../../components/Navbar/useNavActive';

const today = new Date();
const prior = new Date().setDate(today.getDate() - 30);
const priorDate = new Date(prior);

const formDefault = {
  customer_id: '',
  products: [],
  date_from:
    priorDate.getFullYear() +
    '-' +
    (priorDate.getMonth() + 1) +
    '-' +
    priorDate.getDate(),
  date_to:
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
  page: 0,
  page_size: 50,
  order_by: null,
  direction: null,
};

const SalesPerProducts = () => {
  useNavActive([
    'master_databases',
    'sub_ledger_database',
    'sales_per_products_report',
  ]);
  //
  const history = useHistory();
  const [formData, setFormData] = useState(formDefault);
  const [salesPerProductData, setSalesPerProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [reloadTable, setReloadTable] = useState(1);
  const [productsOption, setProductsOption] = useState([]);
  const [overallNetTotal, setOverallNetTotal] = useState(0);

  useNavActive([
    'master_databases',
    'sub_ledger_database',
    'sales_per_products_report',
  ]);

  // useEffect(() => loadLedgerData(formData), []);

  useEffect(() => {
    getAllProductsData();
    loadLedgerData(formData);
  }, []);

  const getAllProductsData = () => {
    getProductsData()
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          // Product Option
          setProductsOption(
            result.map((value) => ({
              type: 'product',
              value: value.product_id,
              label: value.product_name,
              product: value.product_name,
            })),
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadLedgerData = async (form) => {
    console.log(form);
    setReloadTable(Math.floor(Math.random() * 101));
    setLoading(true);
    setSalesPerProductData([]);
    let dateFrom = new Date(form.date_from);
    let dateTo = new Date(form.date_to);
    let allow = 0;
    if (dateFrom.getTime() === dateTo.getTime()) {
      allow = 1;
    } else if (dateFrom.getTime() < dateTo.getTime()) {
      allow = 1;
    }

    if (allow === 1) {
      try {
        let res = await requestGetSalesPerProductLedger(form);

        const { code, result, total_rows } = res.data.response;

        if (code !== '00000') {
          toast.error(result);
          return;
        }
        let tmp = [];
        setOverallNetTotal(
          numberFormatCurrency(res.data.response.overall_net_total ?? 0),
        );
        _.each(result, (row) => {
          let dsc;
          if (row.dsc) {
            dsc = row.dsc + ' %';
          }
          tmp.push({
            id: row.id,
            year: row.year,
            month: row.month,
            si_date: row.si_date ?? '',
            booked_by: row.booked_by ?? '',
            dr_number: row.dr_number ?? '',
            printed_si_number: row.printed_si_number ?? '',
            digital_si_number: row.digital_si_number ?? '',
            customer_name: row.customer_name ?? '',
            so_number: row.so_number ?? '',
            channel: row.channel ?? '',
            tagging: row?.tagging ?? '',
            segment: row.segment ?? '',
            product_code: row.product_code ?? '',
            is_promotional: row.is_promotional ?? '',
            product: row.product ?? '',
            batch_no: row.batch_no ?? '',
            exp_date: row.exp_date ?? '',
            fg: row.fg ?? '',
            qty: row.qty ?? '',
            dsc: dsc ?? '',
            price: numberFormatCurrency(row.price ?? 0),
            gross: numberFormatCurrency(row.gross ?? 0),
            net: numberFormatCurrency(row.net ?? 0),
          });
        });
        setSalesPerProductData(tmp);
        setTotalRow(total_rows);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('Error occured. Please try again later');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.warning('Please provide valid date!');
    }
  };

  const handleSelectedTab = (key) => {
    if (key === 'sales_ledger') {
      history.push('/ledgers/sales');
    } else if (key === 'receivables_ledger') {
      history.push('/ledgers/receivables');
    } else if (key === 'inventories_ledger') {
      history.push('/ledgers/inventories');
    } else if (key === 'sales_per_product_ledger') {
      history.push('/ledgers/sales-per-products');
    } else if (key === 'vatable_sales_ledger') {
      history.push('/ledgers/vatable-sales');
    } else if (key === 'product_ledger') {
      history.push('/ledgers/products');
    } else if (key === 'ytd_sales_ledger') {
      history.push('/ledgers/ytd-sales');
    } else {
      history.push('/ledgers/sales');
    }
  };

  const getCustomer = (e) => {
    setFormData({
      ...formData,
      customer_id: e.target.value,
    });
  };

  const getDate = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeProducts = (e) => {
    let tmp_products = [];
    e.target.value.map((row) => {
      tmp_products.push(row.value);
    });
    setFormData({
      ...formData,
      products: tmp_products,
    });
  };

  const handleSearch = () => loadLedgerData(formData);

  const OnHandleExport = () => {
    let tmp = {
      id: formData.customer_id,
      customer_id: formData.customer_id,
      date_from: formData.date_from,
      date_to: formData.date_to,
      products: formData.products,
      tab: 'sales_per_product',
    };
    console.log(tmp);
    toast.loading('Exporting data in progress, Please wait...', {
      position: toast.POSITION.TOP_RIGHT,
    });
    requestSalesPerProductExport(tmp)
      .then((res) => {
        console.log({ res });
        if (res.data.response.code === '00000') {
          console.log(res);
          window.location.href = res.data.response.result;
          toast.dismiss();
        } else {
          Swal.fire({
            title: 'Error',
            text: res,
            icon: 'error',
          });
        }
      })
      .catch((err) => {});
  };

  const handleDataTableChange = (params) => {
    console.log(params);
    let temp = {
      ...formData,
      ...params,
    };

    //setFormData(temp);
    loadLedgerData(temp);
  };

  return (
    <SalesPerProductReportScreen
      //throw parameter or props.
      formData={formData}
      salesPerProductData={salesPerProductData}
      loading={loading}
      totalRow={totalRow}
      reloadTable={reloadTable}
      selectedTab={'sales_per_product_ledger'}
      handleSelectedTab={handleSelectedTab}
      handleChange={getCustomer}
      dateChange={getDate}
      handleExport={OnHandleExport}
      handleSearch={handleSearch}
      handleDataTableChange={handleDataTableChange}
      productsOption={productsOption}
      handleChangeProducts={handleChangeProducts}
      overallNetTotal={overallNetTotal}
    />
  );
};

export default SalesPerProducts;
