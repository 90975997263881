import makeApiRequest from './makeApiCall';

// export const warehousePalletData = async () => {
//   return await api.get(`/inventorytransfer/product/list`);
// };

export const getWarehouseZonesWithParam = async (data, files = null) => {
  return await makeApiRequest(
    `/v2/warehouse/zones/param`,
    `GET`,
    null,
    data,
    files,
  );
};

export const postCreateWarehouseZone = async (data, files = null) => {
  return await makeApiRequest(`/v2/warehouse/zones/add`, `POST`, null, data);
};

export const postUpdateWarehouseZone = async (data, files = null) => {
  return await makeApiRequest(`/v2/warehouse/zones/update`, `POST`, null, data);
};
