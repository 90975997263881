import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import axiosInstance from '../../axiosInstance';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteRoleIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';

import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme } from '@material-ui/core/styles';

import DefaultLayout from '../../Layouts/DefaultLayout';
import PrimaryDataTable from '../../PrimaryDataTable';
import {
  OverlayTrigger,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Tooltip as BSTooltip,
} from 'react-bootstrap';
import { PlusCircleFill, Arrow90degLeft, Trash } from 'react-bootstrap-icons';
import { AlertDefaultError, PermissionsGate } from '../../PermissionsGate';

/* SEARCH */
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const defaultTheme = createTheme();
const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    textField: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      margin: theme.spacing(1, 0.5, 1.5),
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme },
);

function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(0),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const UserRolesList = () => {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]); //need to set on filter and pass the data to datagrid
  const { id } = useParams();
  const [userRole, setUserRole] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  /* Search */
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = userRole.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setFilteredData(filteredRows);
  };
  /* Continuation of Search */

  const handleIdToCreateRolePerUser = (id) => {
    history.push(`/user/${id}/add/role`);
  };

  const handleDeleteRolePerUser = (data) => {
    const userRoleId = data.row.user_role_id;

    Swal.fire({
      title: 'Do you want to delete it?',
      showDenyButton: true,
      confirmButtonText: `Yes Delete it!`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete('/user/role/delete', {
            data: {
              userRoleId: userRoleId,
            },
          })
          .then((res) =>
            res.data.response.code === '00006'
              ? Swal.fire({
                  icon: 'success',
                  text: res.data.response.result,
                }).then((result) => {
                  if (result.isConfirmed) {
                    history.push(`/user-control`);
                  }
                })
              : null,
          );
      }
    });
  };

  const columns = [
    { field: 'username', headerName: 'Username', flex: 1, minWidth: 100 },
    { field: 'role', headerName: 'Role Name', flex: 1, minWidth: 100 },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (userRole) => {
        return (
          <>
            <div className="btn-action-buttons">
              <Button
                className="btn-sm btn-action"
                variant="link"
                onClick={() => handleDeleteRolePerUser(userRole)}
              >
                <Tooltip title="Delete Role">
                  <Trash />
                </Tooltip>
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let componentMounted = true;

    const loadUserRole = async () => {
      const res = await axiosInstance.get(`/user/${id}/role`, {
        method: 'GET',
      });
      if (componentMounted) {
        setUserRole(res.data.response.result);
        setFilteredData(res.data.response.result);
      }
    };
    loadUserRole();
    return () => {
      componentMounted = false;
    };
  }, [id]);
  const [pageSize, setPageSize] = useState(50);
  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const wrapColumns = useRef(columns);

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'role',
      sort: 'asc',
    },
  ]);
  return (
    <DefaultLayout title="User Management">
      <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
        <Tab
          eventKey="home"
          title="User Role List"
          className="default-tab-content"
        >
          <Row>
            <Col lg="12" className="mb-2">
              <div className="text-right">
                <Button
                  id="add_user"
                  variant="success"
                  className="btn-add-create btn-md"
                  onClick={() => handleIdToCreateRolePerUser(id)}
                >
                  <PlusCircleFill /> Assign Role
                </Button>

                <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <BSTooltip id="tooltip-bottom">
                      Back to User Control List
                    </BSTooltip>
                  }
                >
                  <Link to="/user-control" className="btn btn-info btn-md ml-2">
                    <Arrow90degLeft /> Users Control
                  </Link>
                </OverlayTrigger>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <PrimaryDataTable
                rows={filteredData}
                columns={wrapColumns.current}
                getRowId={(row) => row.user_role_id}
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(''),
                  },
                }}
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </DefaultLayout>
  );
};

export default UserRolesList;
