import makeApiRequest from './makeApiCall';

export const getWarehouseFloors = async (warehouse_id) => {
  return await makeApiRequest('/warehouse/floor', 'POST', null, {
    warehouse_id,
  });
};

export const getFloorZones = async ({ warehouse_id, floor_id }) => {
  return await makeApiRequest('/warehouse/floor/zone', 'POST', null, {
    warehouse_id,
    floor_id,
  });
};

export const getFloorPallets = async ({ warehouse_id, floor_id }) => {
  return await makeApiRequest('/v2/warehouse/zone/assignment', 'GET', null, {
    warehouse_id,
    floor_id,
  });
};

export const tagUseablePallet = async (data) => {
  return await makeApiRequest(
    '/v2/warehouse/zone/assignment/usable',
    'POST',
    null,
    data,
  );
};

export const removeZoneAssigned = async (data) => {
  return await makeApiRequest(
    '/v2/warehouse/zone/assignment/remove',
    'POST',
    null,
    data,
  );
};

export const updateZoneAssigned = async (data) => {
  return await makeApiRequest(
    '/v2/warehouse/zone/assignment/post',
    'POST',
    null,
    data,
  );
};

export const getZoneAssignment = async (id) => {
  return await makeApiRequest('/warehouse/zones/assignment/' + id, 'GET', null);
};

export const exportZoneAssignment = async (data) => {
  return await makeApiRequest(
    '/v2/warehouse/zone/assignment/export',
    'POST',
    null,
    data,
  );
};
