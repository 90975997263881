import { GridToolbar } from '@mui/x-data-grid-pro';
import { Fragment, useState } from 'react';
import { Col, Dropdown, Form, Row, Tab, Tabs } from 'react-bootstrap';

import DefaultLayout from '../../../components/Layouts/DefaultLayout';
import PrimaryDataTable from '../../../components/PrimaryDataTable';

import { getCustomers } from '../../../api/smppProductMaintenance';
import PrimaryAsyncSelect from '../../../components/PrimaryAsyncSelect';
import ExportFormModal from '../components/ExportFormModal';
import ImportFormModal from '../components/ImportFormModal';
import UpdateFormModal from '../components/UpdateFormModal';
import { productMaintenanceColumns } from '../const';

const ProductMaintenanceScreen = ({
  data,
  total,
  formData,
  reloadTable,
  handleFilterChange,
  handleClearFilterSearch,
  handleEditCell,
  onChange,
  loading,
  handleUpdateModal,
  updateModal,
  handleCloseUpdateModal,
  handleSubmitUpdateModal,
  modalLoading,
  // Export
  exportModal,
  handleExportModal,
  handleCloseExportModal,
  // Import
  //reloadData,
  importModal,
  handleImportModal,
  handleCloseImportModal,
}) => {
  const [key, setKey] = useState('general');

  const columns = productMaintenanceColumns;

  return (
    <Fragment>
      <DefaultLayout title="Product Maintenance">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab
            key={0}
            eventKey={'general'}
            title={'General'}
            className="default-tab-content"
          >
            <Row className="mb-4">
              <Col md="12">
                <Row className="d-flex flex-row">
                  <Col md="1">
                    <Form.Label className="mt-2">Show Data</Form.Label>
                  </Col>

                  <Col md="4">
                    <PrimaryAsyncSelect
                      onChange={(e) =>
                        handleFilterChange({
                          target: { name: 'customer_id', value: e.value },
                        })
                      }
                      customFunction={getCustomers}
                      isClearable={true}
                      name={'customer_id'}
                      value={formData.customer_id}
                      config={{
                        searchField: 'customer_name',
                        valueField: 'customer_id',
                        labelField: 'customer_name',
                      }}
                    />
                  </Col>
                  <Col className="text-right" md="7">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Action
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleExportModal}>
                          Export Customer Product
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleImportModal}>
                          Import Customer Product
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="mt-3">
                <Form.Label className="alert alert-info">
                  <strong>Note:</strong> Please double click on the table cell
                  to edit <strong>Customer Product Code.</strong>
                </Form.Label>
              </Col>
            </Row>
            <PrimaryDataTable
              rows={data}
              columns={columns}
              getRowId={(row) => row.id}
              onChange={onChange}
              loading={loading}
              mode="server"
              reload={reloadTable}
              rowCount={total}
              hideFooterSelectedRowCount
              components={{
                Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  value: formData.customer_id,
                  onChange: handleFilterChange,
                  clearSearch: handleClearFilterSearch,
                },
              }}
              onCellEditCommit={handleEditCell}
            />
          </Tab>
        </Tabs>
      </DefaultLayout>

      <UpdateFormModal
        toggle={updateModal.toggle}
        data={updateModal.data}
        closeModal={handleCloseUpdateModal}
        onSubmit={handleSubmitUpdateModal}
        loading={modalLoading}
      />

      <ExportFormModal
        toggle={exportModal.toggle}
        closeModal={handleCloseExportModal}
      />

      <ImportFormModal
        reloadData={onChange}
        toggle={importModal.toggle}
        closeModal={handleCloseImportModal}
      />
    </Fragment>
  );
};

export default ProductMaintenanceScreen;
