import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// this css is for global only,
// if for a specific page or component only put it on the component or on the css/pages folder
import '@fancyapps/ui/dist/fancybox.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/css/styles.css';
import './assets/css/media.css';
import './assets/css/tabler.css';
import './assets/css/data-grid.css';
import './assets/css/react-datepicker-custom.css';
import './assets/css/select2.css';
// import "./assets/scss/styles.scss";
import './jquery/script';
import 'handsontable/dist/handsontable.full.css';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('app'),
);

/* ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("app")
); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
