import Loader from 'react-loader-spinner';

const PrimaryLoader = ({ visible }) => {
  return (
    visible && (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 1061,
          backgroundColor: 'rgba(255,255,255,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader type="ThreeDots" color="#38d39f" height={50} width={50} />
      </div>
    )
  );
};

export default PrimaryLoader;
